import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import FormControl from "@material-ui/core/FormControl";
// core components
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import MultipleImageUploader from "components/MultipleImageUploader/MultipleImageUploader";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Radio, RadioGroup, TextField } from "@mui/material";
import { editReviewApi } from "API/review.api";
import { getAllReviewsApi } from "API/review.api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content",
    margin: "10%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  image: {
    width: 256,
    height: 150,
  },
  formControl: {
    marginTop: "10%",
    minWidth: 60,
  },
}));

export default function UpdateReviewModal({
  open,
  onClose,
  reviewList,
  setReviewList,
  setFilteredReviewList,
  review,
  customerDropDown,
  productsDropDown,
  selectedImagesToUpload,
  setSelectedImagesToUpload,
}) {
  const classes = useStyles();
  const [progressing, setProgressing] = useState(false);
  const [name, setName] = useState("");
  const [customer, setCustomer] = useState(undefined);
  const [product, setProduct] = useState(null);
  const [isLive, setIsLive] = useState(undefined);
  const [comment, setComment] = useState("");
  const [errors, setErrors] = useState({});
  const [inputValue, setInputValue] = React.useState("");
  const [reviewCustomer, setReviewCustomer] = useState(undefined);
  const [reviewProduct, setReviewProduct] = useState(undefined);
  const [dataSources, setDataSources] = useState([]);
  // const [selectedImagesToUpload, setSelectedImagesToUpload] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [updateInputBody, setUpdateInputBody] = useState({});

  //end
  const validate = () => {
    let valid = true;
    let errorsTemp = {};
    setUpdateInputBody({});
    updateInputBody.id = review?.id;

    if (!name) {
      valid = false;
      errorsTemp["name"] = "Name is required";
    }

    if (name !== review?.name) {
      updateInputBody.name = name;
    }

    if (!customer) {
      valid = false;
      errorsTemp["customer"] = "Customer is required";
    }

    if (customer?.phoneNumber !== review?.customer?.phoneNumber) {
      updateInputBody.customer = customer;
    }
    if (!product) {
      valid = false;
      errorsTemp["product"] = "Product is required";
    }

    if (product?.name !== review?.product?.name) {
      updateInputBody.product = product;
    }
    if (!comment) {
      valid = false;
      errorsTemp["comment"] = "Comment is required";
    }

    if (comment !== review?.comment) {
      updateInputBody.comment = comment;
    }

    if (isLive !== review?.isLive) {
      updateInputBody.isLive = isLive;
    }

    var filteredImages = [];
    if (imagesList?.length > 0) {
      filteredImages = imagesList.filter((e) => e?.action !== "added");
      updateInputBody.images = filteredImages;
    }

    if (filteredImages?.length === 0) {
      updateInputBody.images = [];
    }

    if (filteredImages?.length === 0 && selectedImagesToUpload?.length === 0) {
      valid = false;
      errorsTemp["imageUrl"] = "Image is required";
    }

    setErrors(errorsTemp);
    return valid;
  };

  const editReview = async (e) => {
    setProgressing(true);
    e.preventDefault();
    if (validate()) {
      try {
        const newReview = await editReviewApi(
          updateInputBody,
          selectedImagesToUpload
        );
        if (newReview) {
          const reviews = await getAllReviewsApi();
          if (reviews) {
            setReviewList(reviews);
            setFilteredReviewList(reviews);
            onClose();
            setProgressing(false);
          }
        }
      } catch (error) {
        onClose();
        setProgressing(false);
        return;
      }
    } else {
      setProgressing(false);
    }
  };

  const nameOnChange = (event) => {
    setName(event.target.value);
    if (errors["name"]) {
      setErrors({ ...errors, name: null });
    }
  };

  const isLiveOnChange = (event) => {
    setIsLive(event.target.value === "true" ? true : false);
    if (errors["isLive"]) {
      setErrors({ ...errors, isLive: null });
    }
  };

  const commentOnChange = (event) => {
    setComment(event.target.value);
    if (errors["comment"]) {
      setErrors({ ...errors, comment: null });
    }
  };

  useEffect(() => {
    if (review) {
      setName(review.name);
      setCustomer(review.customer);
      setReviewCustomer(review?.customer);
      setInputValue(review.customer?.phoneNumber);
      setProduct(review.product);
      setReviewProduct(review?.product);
      setIsLive(review.isLive);
      setComment(review.comment);
      setImagesList(review?.images);
      setDataSources(review?.images);
    }
  }, [review]);

  if (progressing) {
    return (
      <div style={{ margin: "0 auto" }}>
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    );
  } else {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Scrollbars style={{ height: 500 }}>
          <Fade in={open}>
            <GridContainer>
              <GridItem xs={24} sm={24} md={18} style={{ width: "100%" }}>
                <Card>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Edit Review</h4>
                    <p className={classes.cardCategoryWhite}>
                      Edit Review Details
                    </p>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Name"
                          id="Name"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={name}
                          onChange={nameOnChange}
                          error={errors["name"] ? true : false}
                          errorHelperText={errors["name"]}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        {reviewCustomer !== undefined && (
                          <Autocomplete
                            id="combo-box-demo"
                            options={customerDropDown}
                            value={reviewCustomer}
                            onChange={(event, newValue) => {
                              setReviewCustomer(newValue);
                              setCustomer(newValue);
                              if (errors["customer"]) {
                                setErrors({ ...errors, customer: null });
                              }
                            }}
                            getOptionLabel={(option) => option.phoneNumber}
                            style={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Customer"
                                variant="outlined"
                                error={errors["customer"] ? true : false}
                                helperText={errors["customer"]}
                              />
                            )}
                          />
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        {reviewProduct !== undefined && (
                          <Autocomplete
                            id="combo-box-demo"
                            options={productsDropDown}
                            value={reviewProduct}
                            onChange={(event, newValue) => {
                              setReviewProduct(newValue);
                              setProduct(newValue);
                              if (errors["product"]) {
                                setErrors({ ...errors, product: null });
                              }
                            }}
                            getOptionLabel={(option) => option?.name}
                            style={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Product"
                                variant="outlined"
                                error={errors["product"] ? true : false}
                                helperText={errors["product"]}
                              />
                            )}
                          />
                        )}
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={8}>
                        {/* <TextareaAutosize
                          aria-label="empty textarea"
                          id="comment"
                          placeholder="Type the review here..."
                          style={{ width: "80%", height: 100 }}
                          value={comment}
                          onChange={commentOnChange}
                          error={errors["comment"] ? true : false}
                          errorHelperText={errors["comment"]}
                        /> */}
                        <TextField
                          id="comment"
                          placeholder="Type the review here..."
                          style={{ width: "80%", height: 100 }}
                          multiline
                          InputProps={{
                            inputComponent: TextareaAutosize,
                            inputProps: {
                              style: {
                                resize: "auto",
                              },
                            },
                          }}
                          value={comment}
                          onChange={commentOnChange}
                          error={errors["comment"] ? true : false}
                          helperText={errors["comment"]}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        {isLive !== undefined && (
                          <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">
                              Is Live
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              value={isLive}
                              onChange={isLiveOnChange}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Live"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Not Live"
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <MultipleImageUploader
                          error={errors["imageUrl"] ? true : false}
                          errorHelperText={errors["imageUrl"]}
                          dataSources={dataSources}
                          setSelectedImagesToUpload={setSelectedImagesToUpload}
                          setImagesList={setImagesList}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" onClick={editReview}>
                      Update Review
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </Fade>
        </Scrollbars>
      </Modal>
    );
  }
}

UpdateReviewModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  reviewList: PropTypes.array,
  setReviewList: PropTypes.func,
  setFilteredReviewList: PropTypes.func,
  review: PropTypes.object,
};
