import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PropTypes from "prop-types";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { editCustomerApi } from "API/customer.api";
import Scrollbars from "react-custom-scrollbars";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { getAllCustomersApi } from "API/customer.api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content",
    margin: "10%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

export default function UpdateCustomerModal({
  open,
  onClose,
  customer,
  setCustomerList,
  setFilteredCustomerList,
  customerList,
}) {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [password, setPassword] = useState("");
  const [customerType, setCustomerType] = useState("customer");
  const [errors, setErrors] = useState({});
  const [progressing, setProgressing] = useState(false);
  const [updateInputBody, setUpdateInputBody] = useState({});

  const validate = () => {
    let valid = true;
    let errorsTemp = {};
    setUpdateInputBody({});
    updateInputBody.id = customer?.id;

    if (!customerType) {
      valid = false;
      errorsTemp["customertype"] = "Customer Type is required";
    }

    if(customerType !== customer?.customerType){
      updateInputBody.customerType = customerType;
    }

    if (!firstName) {
      valid = false;
      errorsTemp["firstName"] = "First Name is required";
    }

    if(firstName !== customer?.firstName){
      updateInputBody.firstName = firstName;
    }

    if (!lastName) {
      valid = false;
      errorsTemp["lastName"] = "Last Name is required";
    }

    if(lastName !== customer?.lastName){
      updateInputBody.lastName = lastName;
    }

    if (!customerAddress) {
      valid = false;
      errorsTemp["customerAddress"] = "Address is required";
    }

    if(customerAddress !== customer?.customerAddress){
      updateInputBody.customerAddress = customerAddress;
    }


    let numberError = validatePhoneNumber(phoneNumber);
    if (numberError !== "") {
      valid = false;
    }
    errorsTemp["phoneNumber"] = numberError;

    if(phoneNumber !== customer?.phoneNumber){
      if(customerList.some((el) => el?.phoneNumber.toLowerCase() === phoneNumber.toLowerCase())){
        valid = false;
        errorsTemp["phoneNumber"] = "Phone Number already exists.";
      }else{
        updateInputBody.phoneNumber = phoneNumber;
      } 
    }

    if(password){
      updateInputBody.password = password;
    }

    setErrors(errorsTemp);
    return valid;
  };

  const validatePhoneNumber = (number) => {
    let result = "";
    if (!number || number === "") {
      result = "Phone Number is required";
    } else if (number.length !== 10) {
      // length is not 10 digits
      result = "Phone Number should be 10 digits";
    } else {
      var patt = new RegExp("[^0-9]");
      if (patt.test(number)) {
        // contains special chars
        result = "Please enter only digits";
      } else if (number.substring(0, 2) !== "07") {
        // if not a mobile number
        result = "First two digits should be 07";
      } else {
        // valid mobile number
      }
    }
    return result;
  };

  const editCustomer = async (e) => {
    setProgressing(true);
    e.preventDefault();
    if (validate()) {
      try {
        const newCustomer = await editCustomerApi(updateInputBody);
        if(newCustomer){
          const customers = await getAllCustomersApi();
          if(customers){
          setCustomerList(customers);
          setFilteredCustomerList(customers);
          onClose();
          setProgressing(false);
          }
        }

      } catch (error) {
        onClose();
        setProgressing(false);
        return;
      }
    } else {
      setProgressing(false);
    }
  };

  const customerTypeOnChange = (event) => {
    setCustomerType(event.target.value);
    if (errors["customerType"]) {
      setErrors({ ...errors, customerType: null });
    }
  };

  const passwordOnChange = (event) => {
    setPassword(event.target.value);
    if (errors["password"]) {
      setErrors({ ...errors, password: null });
    }
  };

  const addressOnChange = (event) => {
    setCustomerAddress(event.target.value);
    if (errors["customerAddress"]) {
      setErrors({ ...errors, customerAddress: null });
    }
  };

  const firstNameOnChange = (event) => {
    setFirstName(event.target.value);
    if (errors["firstName"]) {
      setErrors({ ...errors, firstName: null });
    }
  };

  const lastNameOnChange = (event) => {
    setLastName(event.target.value);
    if (errors["lastName"]) {
      setErrors({ ...errors, lastName: null });
    }
  };

  const phoneNumberOnChange = (event) => {
    setPhoneNumber(event.target.value);
    if (errors["phoneNumber"]) {
      setErrors({
        ...errors,
        phoneNumber: validatePhoneNumber(event.target.value),
      });
    }
  };

  useEffect(() => {
    setFirstName(customer?.firstName);
    setLastName(customer?.lastName);
    setCustomerAddress(customer?.customerAddress);
    setCustomerType(customer?.customerType);
    setPhoneNumber(customer?.phoneNumber);
  }, [customer]);

  if (progressing) {
    return (
      <div style={{ margin: "0 auto" }}>
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    );
  } else {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Scrollbars style={{ height: 500 }}>
          <Fade in={open}>
            <GridContainer>
              <GridItem xs={24} sm={24} md={18} style={{ width: "100%" }}>
                <Card>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Edit Customer</h4>
                    <p className={classes.cardCategoryWhite}>
                      Edit Customer Details
                    </p>
                  </CardHeader>
                  <CardBody>
                  <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="First Name"
                          id="firstName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={firstName}
                          onChange={firstNameOnChange}
                          error={errors["firstName"] ? true : false}
                          errorHelperText={errors["firstName"]}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Last Name"
                          id="lastName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={lastName}
                          onChange={lastNameOnChange}
                          error={errors["lastName"] ? true : false}
                          errorHelperText={errors["lastName"]}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Phone Number"
                          id="phone"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={phoneNumber}
                          onChange={phoneNumberOnChange}
                          error={errors["phoneNumber"] ? true : false}
                          errorHelperText={errors["phoneNumber"]}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Address"
                          id="about-me"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={customerAddress}
                          onChange={addressOnChange}
                          error={errors["customerAddress"] ? true : false}
                          errorHelperText={errors["customerAddress"]}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Address"
                          id="about-me"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            multiline: true,
                            rows: 2,
                          }}
                          value={customerAddress}
                          onChange={addressOnChange}
                          error={errors["customerAddress"] ? true : false}
                          errorHelperText={errors["customerAddress"]}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Password"
                          id="about-me"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          // inputProps={{
                          //   multiline: true,
                          //   rows: 2,
                          // }}
                          value={password}
                          onChange={passwordOnChange}
                          error={errors["password"] ? true : false}
                          errorHelperText={errors["password"]}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <InputLabel id="demo-simple-select-label">
                          Customer Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={customerAddress}
                          defaultValue={customerType}
                          label="Age"
                          onChange={customerTypeOnChange}
                        >
                          <MenuItem value={"customer"}>Customer</MenuItem>
                          <MenuItem value={"admin"}>Admin</MenuItem>
                        </Select>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" onClick={editCustomer}>
                      Edit Customer
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </Fade>
        </Scrollbars>
      </Modal>
    );
  }
}

UpdateCustomerModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  customer: PropTypes.object,
  setCustomerList: PropTypes.func,
  setFilteredCustomerList: PropTypes.func,
  customerList: PropTypes.array,
};
