import {  IconButton, Tooltip } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Delete, Edit} from "@material-ui/icons";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import PropTypes from "prop-types";
import React from "react";
import Switch from "@mui/material/Switch";
import { Chip } from "@mui/material";
import { editProductApi } from "API/product.api";
import { getSingleProductApi } from "API/product.api";
import { getToast } from "util/ToastHelper";

const label = { inputProps: { "aria-label": "Switch demo" } };

const useStyles = makeStyles(styles);

export default function ProductsTable(props) {
  const classes = useStyles();
  const {
    tableHead,
    tableHeaderColor,
    tableData,
    setSelectedProduct,
    handleOpenUpdateModal,
    deleteProduct,
    // eslint-disable-next-line react/prop-types
    setProductList,
    // eslint-disable-next-line react/prop-types
    setFilteredProductList,
  } = props;

  const handleEdit = async (product) => {
    if (product?.id) {
      await getSingleProductApi({ id: product?.id }).then((res) => {
        setSelectedProduct(res);
        handleOpenUpdateModal(true);
      });
    }

    // handleOpenUpdateModal(true);
    //     setSelectedProduct(product);
  };
  const handleDelete = (offer) => {
    deleteProduct(offer);
  };

  const handleIsAvailableSwitchChange = async (event) => {
    let isAvailableInput = event.isAvailable;

    let isAvailableChange = !isAvailableInput;

    const body = {
      id: event?.id,
      isAvailable: isAvailableChange,
    };

    const newProduct = await editProductApi(body);
    const newProductList = tableData.map((cust) => {
      if (cust.id !== event.id) {
        return cust;
      } else {
        return { ...newProduct, isAvailableChange };
      }
    });
    setProductList([...newProductList]);
    setFilteredProductList([...newProductList]);
  };

  const handleIsPopularSwitchChange = async (event) => {
    let isPopularInput = event.isPopular;

    let isPopularChange = !isPopularInput;

    const body = {
      id: event?.id,
      isPopular: isPopularChange,
    };

    const newProduct = await editProductApi(body);
    const newProductList = tableData.map((cust) => {
      if (cust.id !== event.id) {
        return cust;
      } else {
        return { ...newProduct, isPopularChange };
      }
    });
    setProductList([...newProductList]);
    setFilteredProductList([...newProductList]);
  };

  const handleIsInSlideShowSwitchChange = async (event) => {
    let isInSlideShowInput = event.isInSlideShow;

    let isInSlideShowChange = !isInSlideShowInput;

    const body = {
      id: event?.id,
      isInSlideShow: isInSlideShowChange,
    };

    const newProduct = await editProductApi(body);
    const newProductList = tableData.map((cust) => {
      if (cust.id !== event.id) {
        return cust;
      } else {
        return { ...newProduct, isInSlideShowChange };
      }
    });
    setProductList([...newProductList]);
    setFilteredProductList([...newProductList]);
  };

  const handleIsDefectSwitchChange = async (event) => {
    let defectOrClearancePriceValue = event?.defectOrClearancePrice;
    if (!defectOrClearancePriceValue) {
      getToast(true, "DefectOrClearancePrice cannot be empty");
    } else {
      let isDefectInput = event.isDefect;

      let isDefectChange = !isDefectInput;

      const body = {
        id: event?.id,
        isDefect: isDefectChange,
      };

      const newProduct = await editProductApi(body);
      const newProductList = tableData.map((cust) => {
        if (cust.id !== event.id) {
          return cust;
        } else {
          return { ...newProduct, isDefectChange };
        }
      });
      setProductList([...newProductList]);
      setFilteredProductList([...newProductList]);
    }
  };

  const handleIsClearanceSwitchChange = async (event) => {
    let defectOrClearancePriceValue = event?.defectOrClearancePrice;
    if (!defectOrClearancePriceValue) {
      getToast(true, "DefectOrClearancePrice cannot be empty");
    } else {
      let isClearanceInput = event.isClearance;

      let isClearanceChange = !isClearanceInput;

      const body = {
        id: event?.id,
        isClearance: isClearanceChange,
      };

      const newProduct = await editProductApi(body);
      const newProductList = tableData.map((cust) => {
        if (cust.id !== event.id) {
          return cust;
        } else {
          return { ...newProduct, isClearanceChange };
        }
      });
      setProductList([...newProductList]);
      setFilteredProductList([...newProductList]);
    }
  };

  const dataRows = () => {
    return tableData.map((row, index) => (
      <TableRow
        key={index}
        className={classes.tableBodyRow}
        style={{ background: row.stock <= 3 ? "red" : "white" }}
      >
        <TableCell
          className={classes.tableCell}
          style={{ color: row.stock <= 3 ? "white" : "black" }}
        >
          {row?.name}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {row?.categories?.map((cat, index) => (
            <Chip label={cat?.name} key={index} color="secondary" />
          ))}
        </TableCell>
        {/* <TableCell className={classes.tableCell}>
          {row?.brands?.map((brand) => (
            <Chip label={brand?.name} color="secondary" />
          ))}
        </TableCell> */}
        <TableCell
          className={classes.tableCell}
          style={{ color: row.stock <= 3 ? "white" : "black" }}
        >
          {row?.price}
        </TableCell>
        <TableCell
          className={classes.tableCell}
          style={{ color: row.stock <= 3 ? "white" : "black" }}
        >
          {row?.stock}
        </TableCell>

        <TableCell className={classes.tableCell}>
          {row?.defectOrClearancePrice}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {
            <Switch
              {...label}
              checked={row?.isAvailable}
              onClick={() => handleIsAvailableSwitchChange(row)}
              color="secondary"
            />
          }
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Switch
            {...label}
            checked={row?.isPopular}
            onClick={() => handleIsPopularSwitchChange(row)}
            color="secondary"
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Switch
            {...label}
            checked={row?.isInSlideShow}
            onClick={() => handleIsInSlideShowSwitchChange(row)}
            color="secondary"
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Switch
            {...label}
            checked={row?.isDefect}
            onClick={() => handleIsDefectSwitchChange(row)}
            color="secondary"
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Switch
            {...label}
            checked={row?.isClearance}
            onClick={() => handleIsClearanceSwitchChange(row)}
            color="secondary"
          />
        </TableCell>
        {/* <TableCell className={classes.tableCell}>{row.paymentStatus}</TableCell>
        <TableCell className={classes.tableCell}>{row.deliveryStatus}</TableCell>
        <TableCell className={classes.tableCell}>{
          <Button variant="contained" color="primary" onClick={() => handleViewOrder(row.Status)}>View Order</Button>
        }</TableCell> */}
        {/* <TableCell
          className={classes.tableCell}
        >{`${row.quantityThresh} ${row.portionThresh}`}</TableCell>
        <TableCell className={classes.tableCell}>
          {row.type === "discount" || row.type === "percentage"
            ? row.discount
            : row.type === "freeMenu" &&
              `${row?.freeMenu?.quantity} nos ${row?.freeMenu?.portion}  ${row?.freeMenu?.name}`}
        </TableCell> */}
        <TableCell className={classes.tableCell}>
          {/* {row.isAvailable ? (
            <Tooltip title="Block">
              <IconButton onClick={() => handleCancel(row)}>
                <Block fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Un-Block">
              <IconButton onClick={() => handleUnCancel(row)}>
                <Add fontSize="small" />
              </IconButton>
            </Tooltip>
          )} */}
          <Tooltip title="Update Product">
            <IconButton onClick={() => handleEdit(row)}>
              <Edit fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Product">
            <IconButton onClick={() => handleDelete(row)}>
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </TableCell>
        {/* <TableCell className={classes.tableCell}>
          {row.isAvailable ? "Available" : "Not Available"}
        </TableCell> */}
      </TableRow>
    ));
  };
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>{dataRows()}</TableBody>
      </Table>
    </div>
  );
}

ProductsTable.defaultProps = {
  tableHeaderColor: "gray",
};

ProductsTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object),

  menus: PropTypes.array,
  setSelectedProduct: PropTypes.func,
  handleOpenUpdateModal: PropTypes.func,
  deleteProduct: PropTypes.func,
  cancelOffer: PropTypes.func,
  unCancelOffer: PropTypes.func,
};
