import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PropTypes from "prop-types";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { editAdminApi, getAllAdminsApi } from "../../API/admin-user.api";
import Scrollbars from "react-custom-scrollbars";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { InputLabel, MenuItem, Select } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content",
    margin: "10%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

export default function UpdateAdminModal({
  open,
  onClose,
  customer,
  setCustomerList,
  setFilteredCustomerList,
  customerList,
}) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("admin");
  const [errors, setErrors] = useState({});
  const [progressing, setProgressing] = useState(false);
  const [updateInputBody, setUpdateInputBody] = useState({});

  const validate = () => {
    let valid = true;
    let errorsTemp = {};
    setUpdateInputBody({});
    updateInputBody.id = customer?.id;

    if (!userType) {
      valid = false;
      errorsTemp["userType"] = "userType is required";
    }

    if (userType !== customer?.customerType) {
      updateInputBody.customerType = userType;
    }

    if (!firstName) {
      valid = false;
      errorsTemp["firstName"] = "First Name is required";
    }

    if (firstName !== customer?.firstName) {
      updateInputBody.firstName = firstName;
    }

    if (!lastName) {
      valid = false;
      errorsTemp["lastName"] = "Last Name is required";
    }

    if (lastName !== customer?.lastName) {
      updateInputBody.lastName = lastName;
    }

    if (!address) {
      valid = false;
      errorsTemp["address"] = "Address is required";
    }

    if (address !== customer?.address) {
      updateInputBody.address = address;
    }

    if (!email) {
      valid = false;
      errorsTemp["email"] = "Email is required";
    }

    if (email !== customer?.email) {
      if(customerList.some((el) => el?.email.toLowerCase() === email.toLowerCase())){
        valid = false;
        errorsTemp["email"] = "Email already exists.";
      }else{
        updateInputBody.email = email;
      } 
    }

    setErrors(errorsTemp);
    return valid;
  };

  const editCustomer = async (e) => {
    setProgressing(true);
    e.preventDefault();
    if (validate()) {
      try {
        const newCustomer = await editAdminApi(updateInputBody);
        if (newCustomer) {
          const admins = await getAllAdminsApi();
          if (admins) {
            setCustomerList(admins);
            setFilteredCustomerList(admins);
            onClose();
            setProgressing(false);
          }
        }
      } catch (error) {
        onClose();
        setProgressing(false);
        return;
      }
    } else {
      setProgressing(false);
    }
  };

  const customerTypeOnChange = (event) => {
    setUserType(event.target.value);
    if (errors["userType"]) {
      setErrors({ ...errors, userType: null });
    }
  };

  const passwordOnChange = (event) => {
    setPassword(event.target.value);
    if (errors["password"]) {
      setErrors({ ...errors, password: null });
    }
  };

  const addressOnChange = (event) => {
    setAddress(event.target.value);
    if (errors["address"]) {
      setErrors({ ...errors, address: null });
    }
  };

  const firstNameOnChange = (event) => {
    setFirstName(event.target.value);
    if (errors["firstName"]) {
      setErrors({ ...errors, firstName: null });
    }
  };

  const lastNameOnChange = (event) => {
    setLastName(event.target.value);
    if (errors["lastName"]) {
      setErrors({ ...errors, lastName: null });
    }
  };

  const emailOnChange = (event) => {
    setEmail(event.target.value);
    if (errors["email"]) {
      setErrors({ ...errors, email: null });
    }
  };

  useEffect(() => {
    setFirstName(customer?.firstName);
    setLastName(customer?.lastName);
    setAddress(customer?.address);
    setUserType(customer?.userType);
    setEmail(customer?.email);
  }, [customer]);

  if (progressing) {
    return (
      <div style={{ margin: "0 auto" }}>
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    );
  } else {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Scrollbars style={{ height: 500 }}>
          <Fade in={open}>
            <GridContainer>
              <GridItem xs={24} sm={24} md={18} style={{ width: "100%" }}>
                <Card>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Edit Customer</h4>
                    <p className={classes.cardCategoryWhite}>
                      Edit Customer Details
                    </p>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="First Name"
                          id="firstName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={firstName}
                          onChange={firstNameOnChange}
                          error={errors["firstName"] ? true : false}
                          errorHelperText={errors["firstName"]}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Last Name"
                          id="lastName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={lastName}
                          onChange={lastNameOnChange}
                          error={errors["lastName"] ? true : false}
                          errorHelperText={errors["lastName"]}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Email"
                          id="email"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={email}
                          onChange={emailOnChange}
                          error={errors["email"] ? true : false}
                          errorHelperText={errors["email"]}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Address"
                          id="about-me"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={address}
                          onChange={addressOnChange}
                          error={errors["address"] ? true : false}
                          errorHelperText={errors["address"]}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Address"
                          id="about-me"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            multiline: true,
                            rows: 2,
                          }}
                          value={customerAddress}
                          onChange={addressOnChange}
                          error={errors["customerAddress"] ? true : false}
                          errorHelperText={errors["customerAddress"]}
                        />
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Password"
                          id="about-me"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          // inputProps={{
                          //   multiline: true,
                          //   rows: 2,
                          // }}
                          value={password}
                          onChange={passwordOnChange}
                          error={errors["password"] ? true : false}
                          errorHelperText={errors["password"]}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <InputLabel id="demo-simple-select-label">
                          Customer Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={customerAddress}
                          defaultValue={userType}
                          label="Age"
                          onChange={customerTypeOnChange}
                        >
                          <MenuItem value={"customer"}>Customer</MenuItem>
                          <MenuItem value={"admin"}>Admin</MenuItem>
                        </Select>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" onClick={editCustomer}>
                      Edit User
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </Fade>
        </Scrollbars>
      </Modal>
    );
  }
}

UpdateAdminModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  customer: PropTypes.object,
  setCustomerList: PropTypes.func,
  setFilteredCustomerList: PropTypes.func,
  customerList: PropTypes.array,
};
