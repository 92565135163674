import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomerTable from "components/Table/CustomerTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CreateSiteSettings from "./CreateSiteSettings";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Scrollbars from "react-custom-scrollbars";
import UpdateSiteSettings from "./UpdateSiteSettings";
import SiteSettingsTable from "components/Table/SiteSettingsTable";
import { getAllSiteSettingsApi } from "API/sitesetting.api";
import { deleteSiteSettingApi } from "API/sitesetting.api";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  createMenu: {
    marginLeft: "2%",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  ...styles,
  headerRoot: {
    display: "flex",
  },
  headerTitle: {
    flexGrow: "2",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    flexGrow: "1",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: (theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: (theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function SiteSettings() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
  const [siteSettingList, setSiteSettingList] = useState([]);
  const [filteredSiteSettingList, setFilteredSiteSettingList] = useState([]);
  const [searched, setSearched] = useState("");
  const [selectedSiteSetting, setSelectedSiteSetting] = useState({});

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenUpdateModal = () => {
    setOpenUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setOpenUpdateModal(false);

    const localSelectedCustomer = siteSettingList.filter(
      (cust) => cust._id === selectedSiteSetting._id
    )[0];

    if (selectedSiteSetting !== localSelectedCustomer) {
      setSelectedSiteSetting({});
    }
  };
  const deleteSiteSetting = async (siteSetting) => {
    await deleteSiteSettingApi({ id: siteSetting?.id }).then((response) => {
      const newSiteSettingList = siteSettingList.filter(
        (site) => site.id !== siteSetting?.id
      );
      setSiteSettingList([...newSiteSettingList]);
      setFilteredSiteSettingList([...newSiteSettingList]);
    }).catch((err) => {
      console.log("Delete Site Setting Error");
    });
  };
  const requestSearch = (searchValue) => {
    const filteredRows = siteSettingList.filter((row) => {
      return row.key.toLowerCase().includes(searchValue.toLowerCase());
    });
    setFilteredSiteSettingList(filteredRows);
  };
  const onChangeSearch = (searchedValue) => {
    setSearched(searchedValue);
  };
  useEffect(async () => {
    try {
      const siteSettings = await getAllSiteSettingsApi();
      setSiteSettingList(siteSettings);
      setFilteredSiteSettingList(siteSettings);
    } catch (error) {
      setSiteSettingList([]);
    }
  }, []);
  // useEffect(async () => {
  //   const localSelectedCustomer = siteSettingList.filter(
  //     (cust) => cust._id === selectedSiteSetting._id
  //   )[0];

  //   try {
  //     if (selectedSiteSetting !== localSelectedCustomer) {
  //       const customers = await getAllCustomersApi();
  //       setSiteSettingList(customers);
  //       setFilteredSiteSettingList(customers);
  //     }
  //   } catch (error) {
  //     setSiteSettingList([]);
  //   }
  // }, [selectedSiteSetting]);

  return (
    <GridContainer>
      <CreateSiteSettings
        open={open}
        onClose={handleClose}
        siteSettingList={siteSettingList}
        setSiteSettingList={setSiteSettingList}
        setFilteredSiteSettingList={setFilteredSiteSettingList}
      />
      <UpdateSiteSettings
        open={openUpdateModal}
        onClose={handleCloseUpdateModal}
        siteSettingList={siteSettingList}
        setSiteSettingList={setSiteSettingList}
        setFilteredSiteSettingList={setFilteredSiteSettingList}
        siteSetting={selectedSiteSetting}
      />
      <Button
        className={classes.createMenu}
        onClick={handleOpen}
        color="success"
      >
        Create SiteSetting
      </Button>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.headerRoot}>
              <div className={classes.headerTitle}>
                <h4 className={classes.cardTitleWhite}>Site Settings Table</h4>
                <p className={classes.cardCategoryWhite}>
                  List of all Site Settings
                </p>
              </div>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="key..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={searched}
                  onChange={(e) => {
                    e.preventDefault();
                    onChangeSearch(e.target.value);
                    requestSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </CardHeader>
          <Scrollbars style={{ height: 500 }}>
            <CardBody>
              <SiteSettingsTable
                tableHeaderColor="primary"
                tableHead={[
                  "Key",
                  "Value",
                  "Action",
                ]}
                tableData={filteredSiteSettingList}
                setSiteSettingList={setSiteSettingList}
                setSelectedSiteSetting={setSelectedSiteSetting}
                selectedSiteSetting={selectedSiteSetting}
                handleOpenUpdateModal={handleOpenUpdateModal}
                deleteSiteSetting={deleteSiteSetting}
              />
            </CardBody>
          </Scrollbars>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
