import axios from "axios";
import { s3ImageUpload } from "components/S3ImageUploader/S3ImageUploader.js";
import { verifyJwtToken } from "util/JwtExpireHelper.js";
import { getToast } from "util/ToastHelper.js";

import { getLocalStorageProperty } from "../util/LocalStorageHelper.js";
import { deleteImageApi } from "./image.api.js";

const getToken = () => getLocalStorageProperty("carryon-admin-token");
const rootUriOwner = process.env.REACT_APP_API_URL_OWNER;

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = "bearer " + getToken();
  return config;
});

export const addNewBrandApi = async ({
  name,
  slug,
  selectedImagesToUpload,
}) => {
  try {
    const imagesType = "brand";

    try {
      const s3Response = await s3ImageUpload(
        selectedImagesToUpload,
        imagesType
      );
      if (s3Response) {
        const createBrandBody = { name, slug, images: s3Response };
        const { data } = await axios.post(
          `${rootUriOwner}/brands`,
          createBrandBody
        );

        if (data?.id) {
          getToast(true, `Brand added successfully`);
          return Promise.resolve(data);
        }
        getToast(false, data);
      }
    } catch (error) {
      if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
        await verifyJwtToken();
      }
      getToast(false, "Error creating brand");
      return Promise.reject();
    }
  } catch (error) {
    // getToast(false, error.response.data.message.message);
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, "Error creating brand");
    return Promise.reject();
  }
};
export const getAllBrandsApi = async () => {
  try {
    const { data } = await axios.get(`${rootUriOwner}/brands`);

    if (data) {
      // getToast(true, data.data.message);
      return Promise.resolve(data);
    } else {
      getToast(false, "Unable to fetch brands");
      return Promise.reject([]);
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject([]);
  }
};

export const getSingleBrandApi = async (body) => {
  try {
    const { data } = await axios.get(
      `${rootUriOwner}/brands/${body?.id}`,
      body
    );
    if (data?.id) {
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    return Promise.reject({});
  }
};

export const deleteBrandApi = async (brand) => {
  try {
    let brandImages = brand?.images;
    const deleteImageResponse = await deleteImageApi(brandImages);

    if (deleteImageResponse) {
      const body = { id: brand?.id };
      const { data } = await axios.delete(
        `${rootUriOwner}/brands/${brand?.id}`,
        body
      );
      if (data) {
        getToast(true, "Brand Deleted Successfully");
        return Promise.resolve(data);
      } else {
        getToast(false, data);
        return Promise.reject({});
      }
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject({});
  }
};

export const editBrandApi = async (body, selectedImagesToUpload) => {
  try {
    if (selectedImagesToUpload?.length > 0) {
      const imagesType = "brand";
      const s3Response = await s3ImageUpload(
        selectedImagesToUpload,
        imagesType
      );
      if (s3Response) {
        let newImagesArray = body?.images.concat(s3Response);

        body.images = newImagesArray;
      }
    }

    const { data } = await axios.patch(
      `${rootUriOwner}/brands/${body?.id}`,
      body
    );
    if (data) {
      getToast(true, "Brand Updated Sucessfully");
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject({});
  }
};
