import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CreateBrandModal from "./CreateBrandModal";
import UpdateBrandModal from "./UpdateBrandModal";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Scrollbars from "react-custom-scrollbars";
import BrandsTable from "components/Table/BrandsTable";
import { getAllBrandsApi } from "API/brand.api";
import { deleteBrandApi } from "API/brand.api";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  createMenu: {
    marginLeft: "2%",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  ...styles,
  headerRoot: {
    display: "flex",
  },
  headerTitle: {
    flexGrow: "2",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    flexGrow: "1",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: (theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: (theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Brand() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [filteredBrandList, setFilteredBrandList] = useState([]);
  const [searched, setSearched] = useState("");
  const [selectedBrand, setSelectedOffer] = useState({});
  const [selectedImagesToUpload, setSelectedImagesToUpload] = useState([]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImagesToUpload([]);
  };
  const handleOpenUpdateModal = () => {
    setOpenUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setOpenUpdateModal(false);
    setSelectedImagesToUpload([]);
  };

  const requestSearch = (searchValue) => {
    const filteredRows = brandList.filter((row) => {
      return row.name.toLowerCase().includes(searchValue.toLowerCase());
    });
    setFilteredBrandList(filteredRows);
  };
  const onChangeSearch = (searchedValue) => {
    setSearched(searchedValue);
  };
  const deleteBrand = async (brand) => {
    const deletedBrand = await deleteBrandApi(brand).then(() => {
      const newBrandList = brandList.filter(
        (singleBrand) => singleBrand.id !== brand.id
      );
      setBrandList([...newBrandList]);
      setFilteredBrandList([...newBrandList]);
    });

  };

  useEffect(async () => {
    try {
      const brands = await getAllBrandsApi();

      setBrandList(brands);
      setFilteredBrandList(brands);
    } catch (error) {
      setBrandList([]);
    }
  }, []);
  // useEffect(async () => {
  //   const localSelectedOffer = brandList.filter(
  //     (singleOffer) => singleOffer._id === selectedBrand._id
  //   )[0];

  //   try {
  //     if (selectedBrand !== localSelectedOffer) {
  //       const offers = await getAllOffersApi();
  //       setBrandList(offers);
  //     }
  //   } catch (error) {
  //     setBrandList([]);
  //   }
  // }, [selectedBrand]);

  return (
    <GridContainer>
      <CreateBrandModal
        open={open}
        onClose={handleClose}
        brandList={brandList}
        setBrandList={setBrandList}
        setFilteredBrandList={setFilteredBrandList}
        selectedImagesToUpload={selectedImagesToUpload}
        setSelectedImagesToUpload={setSelectedImagesToUpload}
      />
      <UpdateBrandModal
        open={openUpdateModal}
        onClose={handleCloseUpdateModal}
        brandList={brandList}
        setBrandList={setBrandList}
        setFilteredBrandList={setFilteredBrandList}
        brand={selectedBrand}
        setSelectedOffer={setSelectedOffer}
        selectedImagesToUpload={selectedImagesToUpload}
        setSelectedImagesToUpload={setSelectedImagesToUpload}
      />
      <Button
        className={classes.createMenu}
        onClick={handleOpen}
        color="primary"
      >
        Create Brand
      </Button>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.headerRoot}>
              <div className={classes.headerTitle}>
                <h4 className={classes.cardTitleWhite}>Brands Table</h4>
                <p className={classes.cardCategoryWhite}>List of all brands</p>
              </div>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Brand Name..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={searched}
                  onChange={(e) => {
                    e.preventDefault();
                    onChangeSearch(e.target.value);
                    requestSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </CardHeader>
          <Scrollbars style={{ height: 500 }}>
            <CardBody>
              <BrandsTable
                tableHeaderColor="primary"
                tableHead={["Name", "Action"]}
                tableData={filteredBrandList}
                handleOpenUpdateModal={handleOpenUpdateModal}
                deleteBrand={deleteBrand}
                setBrandList={setBrandList}
                setSelectedOffer={setSelectedOffer}
                selectedBrand={selectedBrand}
              />
            </CardBody>
          </Scrollbars>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
