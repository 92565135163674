// @material-ui/core components
import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import Scrollbars from "react-custom-scrollbars";
// core components
import GridItem from "components/Grid/GridItem.js";
import OrderTable from "components/Table/OrderTable.js";
import React, { useEffect, useState } from "react";
import ViewOrderModal from "views/Order/ViewOrderModal";
import { getAllOrdersApi } from "API/order.api";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  ...styles,
  headerRoot: {
    display: "flex",
  },
  headerTitle: {
    flexGrow: "2",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    flexGrow: "1",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: (theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: (theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function TableList({setOrderDetailsForDashboard}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openViewOrderModal, setOpenViewOrderModal] = useState(false);
  const [offerList, setOrderList] = useState([]);
  const [filteredOfferList, setFilteredOrderList] = useState([]);
  const [searched, setSearched] = useState("");
  const [selectedOffer, setSelectedOffer] = useState({});
  const [orderDetails, setOrderDetails] = useState([]);
  const [alldOrderList, setFilteredAllOrderList] = useState([]);
  const [orderTotal, setOrderTotal] = useState(0);
  const [singleOrder, setSingleOrder] = useState();

  const handleOpenUpdateModal = () => {
    setOpenUpdateModal(true);
  };

  const handleOpenViewOrderModal = () => {
    setOpenViewOrderModal(true);
  };

  const handleCloseViewOrderModal = () => {
    setOpenViewOrderModal(false);
  };

  const requestSearch = (searchValue) => {
    if(searchValue == ''){
      setFilteredOrderList(alldOrderList);
    }
    else{
    const filteredRows = filteredOfferList.filter((row) => {
      let stringifiedId = (row?.id).toString();
      return stringifiedId.includes(searchValue.toLowerCase());
    });
    setFilteredOrderList(filteredRows);
  }
  };
  const onChangeSearch = (searchedValue) => {
    setSearched(searchedValue);
  };

  async function retreiveAllOrderFunction(){
    try{
    const orders = await getAllOrdersApi();
    setFilteredOrderList(orders);
    setOrderDetailsForDashboard(orders);
    setFilteredAllOrderList(orders);
    } catch (error) {
      setFilteredOrderList([]);
      setFilteredAllOrderList([]);

    }
  }


  useEffect(() => {
    retreiveAllOrderFunction();
    try {
      const intervalID = setInterval(() => {
        retreiveAllOrderFunction();
      }, 60000);
      return () => clearInterval(intervalID);
    } catch (error) {
      setFilteredOrderList([]);
      setFilteredAllOrderList([]);

    }
  }, []);


  return (
    <GridContainer>
      <ViewOrderModal
        open={openViewOrderModal}
        onClose={handleCloseViewOrderModal}
        orderDetails={orderDetails}
        orderTotal={orderTotal}
        singleOrder={singleOrder}
      />
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.headerRoot}>
              <div className={classes.headerTitle}>
                <h4 className={classes.cardTitleWhite}>Orders Table</h4>
                <p className={classes.cardCategoryWhite}>List of all orders</p>
              </div>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Order Id..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={searched}
                  onChange={(e) => {
                    e.preventDefault();
                    onChangeSearch(e.target.value);
                    requestSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </CardHeader>
          <Scrollbars style={{ height: 500 }}>
            <CardBody>
              <OrderTable
                tableHeaderColor="primary"
                tableHead={[
                  "id",
                  "Customer Name",
                  "Customer Email",
                  "Customer Phone Number",
                  "Delivery address",
                  "Invoice Number",
                  "Total",
                  "Payment Type ",
                  "Payment Status",
                  "Delivery status",
                  "Action"
                ]}
                tableData={filteredOfferList}
                handleOpenUpdateModal={handleOpenUpdateModal}
                handleOpenViewOrderModal={handleOpenViewOrderModal}
                setOrderList={setOrderList}
                setSelectedOffer={setSelectedOffer}
                selectedOffer={selectedOffer}
                setOrderDetails={setOrderDetails}
                setFilteredOrderList={setFilteredOrderList}
                setOrderTotal={setOrderTotal}
                setSingleOrder={setSingleOrder}
              />
            </CardBody>
          </Scrollbars>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
