import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import GridContainer from 'components/Grid/GridContainer';
import Button from "components/CustomButtons/Button.js";
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import Scrollbars from 'react-custom-scrollbars';
import CardBody from 'components/Card/CardBody';
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import CreateDeliverySettingsModal from './CreateDeliverySettings';
import { getAllDeliverySettingsApi } from 'API/deliverysettings.api';
import DeliverySettingsTable from 'components/Table/DeliverySettingsTable';
import { deleteDeliverySettingApi } from 'API/deliverysettings.api';


const SHIPPING_AREAS_KEYS = [
  {
    key: "COLOMBO_AREA",
    value: "colombo_area",
  },
  {
    key: "COLOMBO_SUBURBS",
    value: "colombo_suburbs",
  },
  {
    key: "OUTSTATION",
    value: "outstation",
  }
]

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  createMenu: {
    marginLeft: "2%",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  ...styles,
  headerRoot: {
    display: "flex",
  },
  headerTitle: {
    flexGrow: "2",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    flexGrow: "1",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: (theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: (theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const DeliverySettings = props => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [shippingAreas, setShippingAreas] = useState([]);
  const [editData, setEditData] = useState({
    data: null,
    type: "create"
  });

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setEditData({
      data: null,
      type: "create"
    });
    setOpen(true);
  };

  const handleClose = () => {
    setEditData({
      data: null,
      type: "create"
    });
    setOpen(false);
  };

  const handleEdit = (data) => {
    data = {
      ...data,
      shippingArea: SHIPPING_AREAS_KEYS.find((area) => area.value === data.shippingArea),
    }
    setEditData({
      data,
      type: "edit"
    });
    setOpen(true);
  }

  const onCreateSuccess = (deliveryData) => {
    setOpen(false);
    setData([...data, deliveryData]);
    setFilteredData([...data, deliveryData]);
    handleClose()
  }

  const onEditSuccess = (deliveryData) => {
    setOpen(false);
    setData(data.map((data) => {
      if (data.id === deliveryData.id) {
        return deliveryData;
      }
      return data;
    }));
    setFilteredData(data.map((data) => {
      if (data.id === deliveryData.id) {
        return deliveryData;
      }
      return data;
    }));
    handleClose()
  }

  const onDelete = async (deliveryData) => {
    try {
      await deleteDeliverySettingApi({id: deliveryData.id});
      setData(data.filter((data) => data.id !== deliveryData.id));
      setFilteredData(data.filter((data) => data.id !== deliveryData.id));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(async () => {
    try {
      const deliveryCosts = await getAllDeliverySettingsApi();
      setData(deliveryCosts);
      setFilteredData(deliveryCosts);

    } catch (error) {
      setData([]);
    }
  }, []);

  useEffect(() => {
    const filteredShippingAreas = SHIPPING_AREAS_KEYS.filter((area) => {
      return !data.find((cost) => cost.shippingArea === area.value);
    });

    setShippingAreas(filteredShippingAreas);
  }, [data]);

  return (
    <GridContainer>
      <CreateDeliverySettingsModal
        type={editData.type}
        data={editData.data}
        open={open} 
        onClose={handleClose} 
        onCreateSuccess={onCreateSuccess}
        onEditSuccess={onEditSuccess}
        shippingAreas={shippingAreas}/>
      <Button
        className={classes.createMenu}
        onClick={handleOpen}
        color="success"
        disabled={shippingAreas.length === 0}
      >
        Create Delivery Setting
      </Button>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.headerRoot}>
              <div className={classes.headerTitle}>
                <h4 className={classes.cardTitleWhite}>Delivery Settings Table</h4>
                <p className={classes.cardCategoryWhite}>
                  List of all Delivery Settings
                </p>
              </div>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="key..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
          </CardHeader>
          <Scrollbars style={{ height: 500 }}>
            <CardBody>
              <DeliverySettingsTable
                tableHeaderColor="primary"
                tableHead={[ 
                  "Shipping Area",
                  "Cost",
                  "Message",
                  "Action",
                ]}
                tableData={filteredData}
                onEdit={(data) => {handleEdit(data)}}
                onDelete={(data) => {onDelete(data)}}
              />
            </CardBody>
          </Scrollbars>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

DeliverySettings.propTypes = {}

export default DeliverySettings