import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { addNewCategoryApi } from "API/category.api";
import Scrollbars from "react-custom-scrollbars";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import MultipleImageUploader from "components/MultipleImageUploader/MultipleImageUploader";
import TextField from "@mui/material/TextField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content",
    margin: "10%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

export default function AddCategoryModal({
  open,
  onClose,
  brandListDropDown,
  categoryList,
  setCategoryList,
  setFilteredCategoryList,
  selectedImagesToUpload,
  setSelectedImagesToUpload
}) {
  const classes = useStyles();

  const [progressing, setProgressing] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  // const [selectedImagesToUpload, setSelectedImagesToUpload] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [brand, setBrand] = useState([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const validate = () => {
    let valid = true;
    let errorsTemp = {};

    if (selectedImagesToUpload?.length === 0) {
      valid = false;
      errorsTemp["imageUrl"] = "Image is required";
    }

    if (!name) {
      valid = false;
      setError("Name is required");
    }

    if (brand?.length === 0) {
      valid = false;
      errorsTemp["brand"] = "Brand is required";
    }


    if(categoryList.some((el) => el?.name.toLowerCase() === name.toLowerCase())){
      valid = false;
      setError("A Category with the same name already exists.");
    }

    setErrors(errorsTemp);
    return valid;
  };

  const addNewCategory = async (e) => {
    setProgressing(true);
    e.preventDefault();
    if (validate()) {
      try {
        const newCategory = await addNewCategoryApi({
          name,
          brands: brand,
          slug: "Test",
          selectedImagesToUpload
        });

        setCategoryList([...categoryList, newCategory]);
        setFilteredCategoryList([...categoryList, newCategory]);
        setName("");
        setSelectedImagesToUpload([]);
        setBrand([]);
        onClose();
        setProgressing(false);
      } catch (error) {
        onClose();
        setSelectedImagesToUpload([]);
        setProgressing(false);
        return;
      }
    } else {
      setProgressing(false);
    }
  };

  const nameOnChange = (event) => {
    setName(event.target.value);
    if (error) {
      setError("");
    }
  };

  if (progressing) {
    return (
      <div style={{ margin: "0 auto" }}>
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    );
  } else {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Scrollbars style={{ height: 500 }}>
          <Fade in={open}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ width: "100%" }}>
                <Card>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Create Category</h4>
                    <p className={classes.cardCategoryWhite}>
                      Enter Category Details
                    </p>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Name"
                          id="Name"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={name}
                          onChange={nameOnChange}
                          error={error ? true : false}
                          errorHelperText={error}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <Autocomplete
                          value={brand}
                          onChange={(event, newValue) => {
                            setBrand(newValue);
                            if (errors["brand"]) {
                              setErrors({ ...errors, brand: null });
                            }
                          }}
                          multiple
                          id="checkboxes-tags-demo"
                          options={brandListDropDown}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option?.name}
                            </li>
                          )}
                          style={{ width: "80%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Brands"
                              placeholder="Brands"
                              variant="outlined"
                              error={errors["brand"] ? true : false}
                              helperText={errors["brand"]}
                            />
                          )}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem>
                        <MultipleImageUploader
                          error={errors["imageUrl"] ? true : false}
                          errorHelperText={errors["imageUrl"]}
                          setSelectedImagesToUpload={setSelectedImagesToUpload}
                          setImagesList={setImagesList}
                        />
                        {/* <ImageUploader
                          onImageUpload={onImageUpload}
                          error={errors["imageUrl"] ? true : false}
                          errorHelperText={errors["imageUrl"]}
                        /> */}
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Button block color="primary" onClick={addNewCategory}>
                      Create Category
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </Fade>
        </Scrollbars>
      </Modal>
    );
  }
}

AddCategoryModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setCategoryList: PropTypes.func,
  setFilteredCategoryList: PropTypes.func,
  categoryList: PropTypes.array,
};
