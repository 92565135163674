import axios from "axios";
import { verifyJwtToken } from "util/JwtExpireHelper.js";
import { getToast } from "util/ToastHelper.js";

import { getLocalStorageProperty } from "../util/LocalStorageHelper.js";

const getToken = () => getLocalStorageProperty("carryon-admin-token");
const rootUriOwner = process.env.REACT_APP_API_URL_OWNER;

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = "bearer " + getToken();
  return config;
});

export const addNewAdminApi = async ({
  firstName,
  lastName,
  address,
  email,
  password,
  userType,
}) => {
  const body = {
    firstName,
    lastName,
    address,
    email,
    password,
    userType,
  };

  try {
    const { data } = await axios.post(`${rootUriOwner}/admin-users`, body);

    if (data?.id) {
      getToast(true, `Admin-User added successfully`);
      return Promise.resolve(data);
    }
    getToast(false, data);
  } catch (error) {
    // getToast(false, error.response.data.message.message);
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, "Error creating Admin user");
    return Promise.reject();
  }
};
export const getAllAdminsApi = async () => {
  try {
    const { data } = await axios.get(`${rootUriOwner}/admin-users`);

    if (data) {
      // getToast(true, data.data.message);
      return Promise.resolve(data);
    } else {
      getToast(false, data.data.message);
      return Promise.reject([]);
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject([]);
  }
};

export const editAdminApi = async (body) => {
  try {
    const { data } = await axios.patch(
      `${rootUriOwner}/admin-users/${body?.id}`,
      body
    );
    if (data) {
      getToast(true, "Admin Updated Successfully");
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject({});
  }
};

export const deleteAdminApi = async ({ id }) => {
  const body = { id };
  try {
    const { data } = await axios.delete(
      `${rootUriOwner}/admin-users/${id}`,
      body
    );
    if (data) {
      getToast(true, "Admin Deleted Successfully");
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, "Error deleting admin");
    return Promise.reject({});
  }
};
