import axios from "axios";
import { s3ImageUpload } from "components/S3ImageUploader/S3ImageUploader.js";
import { verifyJwtToken } from "util/JwtExpireHelper.js";
import { getToast } from "util/ToastHelper.js";

import { getLocalStorageProperty } from "../util/LocalStorageHelper.js";
import { deleteImageApi } from "./image.api.js";

const getToken = () => getLocalStorageProperty("carryon-admin-token");
const rootUriOwner = process.env.REACT_APP_API_URL_OWNER;

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = "bearer " + getToken();
  return config;
});

export const addNewCategoryApi = async ({
  name,
  brands,
  slug,
  selectedImagesToUpload,
}) => {
  const imagesType = "category";
  const body = { name, slug };
  try {
    const s3Response = await s3ImageUpload(selectedImagesToUpload, imagesType);
    if (s3Response) {
      const createCategoryBody = { name, brands, slug, images: s3Response };
      const { data } = await axios.post(
        `${rootUriOwner}/categories`,
        createCategoryBody
      );

      if (data?.id) {
        getToast(true, "Category added successfully");
        return Promise.resolve(data);
      }
      getToast(false, data);
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject();
  }
};

export const getAllCategoriesApi = async () => {
  try {
    const { data } = await axios.get(`${rootUriOwner}/categories`);

    if (data) {
      // getToast(true, data.data.message);
      return Promise.resolve(data);
    } else {
      getToast(false, "Error retreiving categories");
      return Promise.reject([]);
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject([]);
  }
};

export const editCategoryApi = async (body, selectedImagesToUpload) => {
  try {
    if (selectedImagesToUpload?.length > 0) {
      const imagesType = "category";
      const s3Response = await s3ImageUpload(
        selectedImagesToUpload,
        imagesType
      );
      if (s3Response) {
        let newImagesArray = body?.images.concat(s3Response);

        body.images = newImagesArray;
      }
    }

    const { data } = await axios.patch(
      `${rootUriOwner}/categories/${body?.id}`,
      body
    );
    if (data) {
      getToast(true, "Category Updated Sucessfully");
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject({});
  }
};
export const getSingleCategoryApi = async (body) => {
  try {
    const { data } = await axios.get(
      `${rootUriOwner}/categories/${body?.id}`,
      body
    );
    if (data?.id) {
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    return Promise.reject({});
  }
};

export const deleteCategoryApi = async (category) => {
  try {
    // if (category?.images?.length > 0) {
    let categoryImages = category?.images;
    const deleteImageResponse = await deleteImageApi(categoryImages);

    if (deleteImageResponse) {
      const body = { id: category?.id };
      const { data } = await axios.delete(
        `${rootUriOwner}/categories/${category?.id}`,
        body
      );

      if (data) {
        getToast(true, "Category deleted sucessfully");
        return Promise.resolve(data);
      } else {
        getToast(false, "Error deleting category");
        return Promise.reject({});
      }
    }
    // }
  } catch (err) {
    if(JSON.stringify(err?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, err);
    return Promise.reject({});
  }
};
