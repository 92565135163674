import axios from "axios";
import { verifyJwtToken } from "util/JwtExpireHelper.js";
import { getToast } from "util/ToastHelper.js";

import { getLocalStorageProperty } from "../util/LocalStorageHelper.js";

const getToken = () => getLocalStorageProperty("carryon-admin-token");
const rootUriOwner = process.env.REACT_APP_API_URL_OWNER;

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = "bearer " + getToken();
  return config;
});

export const addNewCustomerApi = async ({
  firstName,
  lastName,
  customerAddress,
  phoneNumber,
  password,
  email,
  customerType,
}) => {
  const body = {
    firstName,
    lastName,
    customerAddress,
    phoneNumber,
    password,
    email,
    customerType,
  };

  try {
    const { data } = await axios.post(`${rootUriOwner}/customers`, body);

    if (data?.id) {
      getToast(true, `${customerType} added successfully`);
      return Promise.resolve(data);
    }
    getToast(false, data);
  } catch (error) {
    // getToast(false, error.response.data.message.message);
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, "Error creating customer");
    return Promise.reject();
  }
};
export const getAllCustomersApi = async () => {
  try {
    const { data } = await axios.get(`${rootUriOwner}/customers`);

    if (data) {
      // getToast(true, data.data.message);
      return Promise.resolve(data);
    } else {
      getToast(false, data.data.message);
      return Promise.reject([]);
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject([]);
  }
};

export const editCustomerApi = async (body) => {
  try {
    const { data } = await axios.patch(
      `${rootUriOwner}/customers/${body?.id}`,
      body
    );
    if (data) {
      getToast(true, "Customer Updated Sucessfully");
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject({});
  }
};

export const deleteCustomerApi = async ({ id }) => {
  const body = { id };
  try {
    const { data } = await axios.delete(
      `${rootUriOwner}/customers/${id}`,
      body
    );
    if (data) {
      getToast(true, "Customer Deleted Successfully");
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, "Could not delete customer");
    return Promise.reject({});
  }
};
