import axios from "axios";
import { verifyJwtToken } from "util/JwtExpireHelper.js";
import { getToast } from "util/ToastHelper.js";

import { getLocalStorageProperty } from "../util/LocalStorageHelper.js";

const getToken = () => getLocalStorageProperty("carryon-admin-token");
const rootUriOwner = process.env.REACT_APP_API_URL_OWNER;

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = "bearer " + getToken();
  return config;
});

export const addNewSiteSettingApi = async ({ key, value }) => {
  const body = { key, value };

  try {
    const { data } = await axios.post(`${rootUriOwner}/site-settings`, body);

    if (data?.id) {
      getToast(true, `Site Setting added successfully`);
      return Promise.resolve(data);
    }
    getToast(false, data.data.message);
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    // getToast(false, error.response.data.message.message);
    getToast(false, "Error creating site setting");
    return Promise.reject();
  }
};
export const getAllSiteSettingsApi = async () => {
  try {
    const { data } = await axios.get(`${rootUriOwner}/site-settings`);

    if (data) {
      // getToast(true, data.data.message);
      return Promise.resolve(data);
    } else {
      getToast(false, data.data.message);
      return Promise.reject([]);
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject([]);
  }
};

export const editSiteSettingApi = async ({ key, value, id }) => {
  const body = { key, value, id };
  try {
    const { data } = await axios.patch(
      `${rootUriOwner}/site-settings/${id}`,
      body
    );
    if (data) {
      getToast(true, "Site Setting Updated Sucessfully");
      return Promise.resolve(data);
    } else {
      getToast(false, data.data.message);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject({});
  }
};

export const deleteSiteSettingApi = async ({ id }) => {
  const body = { id };
  try {
    const { data } = await axios.delete(
      `${rootUriOwner}/site-settings/${id}`,
      body
    );
    if (data) {
      getToast(true, "Site Setting Deleted Successfully");
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject({});
  }
};
