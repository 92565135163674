import axios from "axios";
import { s3ImageUpload } from "components/S3ImageUploader/S3ImageUploader.js";
import { verifyJwtToken } from "util/JwtExpireHelper.js";
import { getToast } from "util/ToastHelper.js";

import { getLocalStorageProperty } from "../util/LocalStorageHelper.js";
import { deleteImageApi } from "./image.api.js";

const getToken = () => getLocalStorageProperty("carryon-admin-token");
const rootUriOwner = process.env.REACT_APP_API_URL_OWNER;

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = "bearer " + getToken();
  return config;
});

export const addNewProductApi = async (body, selectedImagesToUpload) => {
  try {
    const imagesType = "product";
    const s3Response = await s3ImageUpload(selectedImagesToUpload, imagesType);
    if (s3Response) {
      const createProductBody = { ...body, images: s3Response };
      const { data } = await axios.post(
        `${rootUriOwner}/products`,
        createProductBody
      );

      if (data?.id) {
        getToast(true, `Product added successfully`);
        return Promise.resolve(data);
      }
      getToast(false, data);
    }
  } catch (error) {
    // getToast(false, error.response.data.message.message);
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, "Error creating product");
    return Promise.reject();
  }
};
export const getAllProductsApi = async () => {
  const body = {};
  try {
    const { data } = await axios.get(`${rootUriOwner}/products?isDeleted=false`, body);

    if (data?.filteredProducts) {
      // getToast(true, data.data.message);
      return Promise.resolve(data?.filteredProducts);
    } else {
      getToast(false, data);
      return Promise.reject([]);
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject([]);
  }
};

export const editProductApi = async (body, selectedImagesToUpload) => {
  try {
    if (selectedImagesToUpload?.length > 0) {
      const imagesType = "product";
      const s3Response = await s3ImageUpload(
        selectedImagesToUpload,
        imagesType
      );
      if (s3Response) {
        let newImagesArray = body?.images.concat(s3Response);

        body.images = newImagesArray;
      }
    }
    const { data } = await axios.patch(
      `${rootUriOwner}/products/${body?.id}`,
      body
    );
    if (data) {
      getToast(true, "Product Updated Sucessfully");
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, "Error updating poduct");
    return Promise.reject({});
  }
};

export const getSingleProductApi = async (body) => {
  try {
    const { data } = await axios.get(
      `${rootUriOwner}/products/id/${body?.id}`,
      body
    );
    if (data?.id) {
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    return Promise.reject({});
  }
};

export const deleteProductApi = async (product) => {
  try {
    // let productImages = product?.images;
    // const deleteImageResponse = await deleteImageApi(productImages);

    // if (deleteImageResponse) {
      const body = { id: product?.id };
      const { data } = await axios.delete(
        `${rootUriOwner}/products/${product?.id}`,
        body
      );
      if (data) {
        getToast(true, "Product Deleted Successfully");
        return Promise.resolve(data);
      } else {
        getToast(false, data);
        return Promise.reject({});
      }
    // }
  } catch (err) {
    if(JSON.stringify(err?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, JSON.stringify(err?.response?.data?.message));
    return Promise.reject({});
  }
};
