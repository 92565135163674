import { IconButton, Tooltip } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Delete, Edit } from "@material-ui/icons";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import PropTypes from "prop-types";
import React from "react";
import { getSingleBrandApi } from "API/brand.api";

const useStyles = makeStyles(styles);

export default function BrandsTable(props) {
  const classes = useStyles();
  const {
    tableHead,
    tableHeaderColor,
    tableData,
    setSelectedOffer,
    handleOpenUpdateModal,
    deleteBrand,
  } = props;

  const handleEdit = async (brand) => {
    if(brand?.id){
      await getSingleBrandApi({ id: brand?.id }).then((res) => {
        handleOpenUpdateModal(true);
        setSelectedOffer(res);
      });
    }
    
  };
  const handleDelete = (offer) => {
    deleteBrand(offer);
  };

  const dataRows = () => {
    return tableData.map((row, index) => (
      <TableRow key={index} className={classes.tableBodyRow}>
        {/* <TableCell className={classes.tableCell}>{row.id}</TableCell> */}
        <TableCell className={classes.tableCell}>{row?.name}</TableCell>
        <TableCell className={classes.tableCell}>
          {/* {row.isAvailable ? (
            <Tooltip title="Block">
              <IconButton onClick={() => handleCancel(row)}>
                <Block fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Un-Block">
              <IconButton onClick={() => handleUnCancel(row)}>
                <Add fontSize="small" />
              </IconButton>
            </Tooltip>
          )} */}
          <Tooltip title="Update Brand">
            <IconButton onClick={() => handleEdit(row)}>
              <Edit fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Brand">
            <IconButton onClick={() => handleDelete(row)}>
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </TableCell>
        {/* <TableCell className={classes.tableCell}>
          {row.isAvailable ? "Available" : "Not Available"}
        </TableCell> */}
      </TableRow>
    ));
  };
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>{dataRows()}</TableBody>
      </Table>
    </div>
  );
}

BrandsTable.defaultProps = {
  tableHeaderColor: "gray",
};

BrandsTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object),
  setSelectedOffer: PropTypes.func,
  handleOpenUpdateModal: PropTypes.func,
  deleteBrand: PropTypes.func,
  cancelOffer: PropTypes.func,
  unCancelOffer: PropTypes.func,
};
