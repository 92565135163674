import axios from "axios";
import { s3ImageUpload } from "components/S3ImageUploader/S3ImageUploader.js";
import { getToast } from "util/ToastHelper.js";

import { getLocalStorageProperty } from "../util/LocalStorageHelper.js";
import { deleteImageApi } from "./image.api.js";

const getToken = () => getLocalStorageProperty("carryon-admin-token");
const rootUriOwner = process.env.REACT_APP_API_URL_OWNER;

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = "bearer " + getToken();
  return config;
});

export const addNewReviewApi = async ({
  name,
  customer,
  product,
  comment,
  isLive,
  selectedImagesToUpload,
}) => {
  // const body = { key, value };
  const imagesType = "review";

  try {
    const s3Response = await s3ImageUpload(selectedImagesToUpload, imagesType);

    if (s3Response) {
      const createReviewBody = {
        name,
        customer,
        product,
        comment,
        isLive,
        images: s3Response,
      };
      const { data } = await axios.post(
        `${rootUriOwner}/reviews`,
        createReviewBody
      );

      if (data?.id) {
        getToast(true, `Review added successfully`);
        return Promise.resolve(data);
      }
      getToast(false, data);
    }
  } catch (error) {
    // getToast(false, error.response.data.message.message);
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, "Error creating review");
    return Promise.reject();
  }
};
export const getAllReviewsApi = async () => {
  try {
    const { data } = await axios.get(`${rootUriOwner}/reviews`);

    if (data) {
      // getToast(true, data.data.message);
      return Promise.resolve(data);
    } else {
      getToast(false, data.data.message);
      return Promise.reject([]);
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject([]);
  }
};

export const editReviewApi = async (body, selectedImagesToUpload) => {
  try {
    if (selectedImagesToUpload?.length > 0) {
      const imagesType = "review";
      const s3Response = await s3ImageUpload(
        selectedImagesToUpload,
        imagesType
      );
      if (s3Response) {
        let newImagesArray = body?.images.concat(s3Response);

        body.images = newImagesArray;
      }
    }

    const { data } = await axios.patch(
      `${rootUriOwner}/reviews/${body?.id}`,
      body
    );
    if (data?.id) {
      getToast(true, "Review Updated Sucessfully");
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, "Error updating review");
    return Promise.reject({});
  }
};

export const getSingleReviewApi = async (body) => {
  try {
    const { data } = await axios.patch(
      `${rootUriOwner}/reviews/${body?.id}`,
      body
    );
    if (data?.id) {
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    return Promise.reject({});
  }
};

export const deleteReviewApi = async (review) => {
  try {
    let reviewImages = review?.images;
    const deleteImageResponse = await deleteImageApi(reviewImages);

    if (deleteImageResponse) {
      const body = { id: review?.id };
      const { data } = await axios.delete(
        `${rootUriOwner}/reviews/${review?.id}`,
        body
      );
      if (data) {
        getToast(true, "Review Deleted Successfully");
        return Promise.resolve(data);
      } else {
        getToast(false, data);
        return Promise.reject({});
      }
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, "Error deleting review");
    return Promise.reject({});
  }
};
