import axios from "axios";
import { getToast } from "util/ToastHelper.js";
import { setLocalStorageProperty } from "../util/LocalStorageHelper.js";

const rootUriOwner = process.env.REACT_APP_API_URL_OWNER;

export const login = async ({ username, password }) => {
  const body = { username, password };

  try {
    const { data } = await axios.post(`${rootUriOwner}/auth/admin/login`, body);

    if (data.access_token) {
      setLocalStorageProperty("carryon-admin-token", data.access_token);
      getToast(true, "Success Login !");

      window.location = "/admin/dashboard";
    } else {
      getToast(false, "Login Failure");
    }
  } catch (error) {
    alert(`error : ${error}`);
  }
};
