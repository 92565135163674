import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import CartTable from "components/Table/CartTable";
import CardMedia from '@mui/material/CardMedia';
import { Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content",
    margin: "10%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  image: {
    width: 256,
    height: 150,
  },
  formControl: {
    marginTop: "10%",
    minWidth: 60,
  },
  payment: {
    
  },
  cardDetails: {
    display: "flex",
    fontSize: "0.9rem",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    gap: "1em",
    marginTop: "1em",
    fontWeight: "bold",
  },
  details: {
    display: "flex",
    fontSize: "0.9rem",
    justifyContent: "start",
    alignItems: "center",
    gap: "1em",
    margin: "0.6em",
  }
}));

export default function ViewOrderModal({ open, onClose, orderDetails, orderTotal, singleOrder }) {
  const classes = useStyles();

  const [orderInformation, setOrderInformation] = useState([]);
  const [progressing, setProgressing] = useState(false);

  const closeOrderView = async (e) => {
    onClose();
  };

  useEffect(() => {
    if (orderDetails) {
      setOrderInformation(orderDetails);
    }
  }, [orderDetails]);

  if (progressing) {
    return (
      <div style={{ margin: "0 auto" }}>
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    );
  } else {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Scrollbars style={{ height: 500 }}>
          <Fade in={open}>
            <GridContainer>
              <GridItem xs={24} sm={24} md={18} style={{ width: "100%" }}>
                <Card>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Order Details</h4>
                    <p className={classes.cardCategoryWhite}>Order Total Amount : Rs {orderTotal}</p>
                  </CardHeader>
                  <CardBody>
                    <CartTable
                      tableHeaderColor="primary"
                      tableHead={[
                        "Product Name",
                        "Product Quantity",
                        "Unit Price",
                      ]}
                      tableData={orderInformation}
                    />
                    <h5>Payment Details</h5>
                    {singleOrder?.payableData != null && (
                      <div className={classes.payment}>
                        {(singleOrder?.payableData?.status === "success" || singleOrder?.payableData?.status === "failure") && (
                          <div className={classes.cardDetails}>
                            <span>{singleOrder?.payableData?.paymentScheme}</span>
                            <span>Cardholder name: {singleOrder?.payableData?.cardHolderName}</span>
                            <span>Card number: {singleOrder?.payableData?.cardNumber}</span>
                          </div>
                        )}
                        <span className={classes.details}>Status : {
                            singleOrder?.payableData?.status === "success" ? (
                              <Chip label="SUCCESS" color="primary" />
                            ) : singleOrder?.payableData?.status === "failure" ? (
                              <Chip label="FAILURE" color="secondary" />
                            ) : (
                              <Chip label={singleOrder?.payableData?.status?.toUpperCase()} color="secondary" />
                            )
                          }
                        </span>
                        <span className={classes.details}>Transaction Id : {singleOrder?.payableData?.payableTransactionId || "-"}</span>
                        <span className={classes.details}>Order Id : {singleOrder?.payableData?.payableOrderId || "-"}</span>
                      </div>
                    )}
                    {singleOrder?.promoCode != null &&
                      <h5>Promo Code Used : {singleOrder?.promoCode?.code}</h5>
                    }
                    {singleOrder?.bankSlip != null &&
                      <Card sx={{ maxWidth: 345 }}>
                      {/* <Scrollbars style={{ height: 300 }}> */}
                      <CardMedia
                        component="img"
                        height="250"
                        alt={"alt"}
                        image={singleOrder?.bankSlip}
                        title="Bank Transfer"
                        sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                      />
                      {/* </Scrollbars> */}
                    </Card>
                    }
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" onClick={closeOrderView}>
                      Close
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem>
              
              </GridItem>
            </GridContainer>
            
          </Fade>
        </Scrollbars>
      </Modal>
    );
  }
}

ViewOrderModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
