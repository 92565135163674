import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PropTypes from "prop-types";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@mui/material/FormControl";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Scrollbars from "react-custom-scrollbars";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import MultipleImageUploader from "components/MultipleImageUploader/MultipleImageUploader";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { addNewProductApi } from "API/product.api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content",
    margin: "10%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  image: {
    width: 256,
    height: 150,
  },
  formControl: {
    marginTop: "10%",
    minWidth: 60,
  },
}));

export default function CreateProductModal({
  open,
  onClose,
  productList,
  setProductList,
  setFilteredProductList,
  brandListDropDown,
  categoryListDropDown,
  selectedImagesToUpload,
  setSelectedImagesToUpload,
}) {
  const classes = useStyles();

  const [progressing, setProgressing] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [defectOrClearancePrice, setDefectOrClearancePrice] = useState();
  const [stock, setStock] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [errors, setErrors] = useState({});
  const [categoryValue, setCategoryValue] = useState([]);
  const [brand, setBrand] = useState([]);
  const [isPopular, setIsPopular] = useState(true);
  const [inSlideShow, setInSlideShow] = useState(true);
  const [isDefect, setIsDefect] = useState(true);
  const [isClearance, setIsClearance] = useState(true);
  const [isAvailable, setIsAvailable] = useState(true);
  // const [selectedImagesToUpload, setSelectedImagesToUpload] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [brandCategories, setBrandCategories] = useState([]);

  const validate = () => {
    let valid = true;
    let errorsTemp = {};

    if (selectedImagesToUpload?.length === 0) {
      valid = false;
      errorsTemp["imageUrl"] = "Image is required";
    }

    if (!name) {
      valid = false;
      errorsTemp["name"] = "Name is required";
    }

    if (
      productList.some((el) => el?.name.toLowerCase() === name.toLowerCase())
    ) {
      valid = false;
      errorsTemp["name"] = "A Product with the same name already exists.";
    }
    if (!description) {
      valid = false;
      errorsTemp["description"] = "Description is required";
    }

    let priceError = validatePrice(price);
    if (priceError !== "") {
      valid = false;
      errorsTemp["price"] = priceError;
    }

    let defectOrClearancePriceError = validateDefectOrClearancePrice(
      defectOrClearancePrice
    );
    if (defectOrClearancePriceError !== "") {
      valid = false;
      errorsTemp["defectOrClearancePrice"] = defectOrClearancePriceError;
    }
    let stockError = validateStock(stock);
    if (stockError !== "") {
      valid = false;
      errorsTemp["stock"] = stockError;
    }
    if (!stock) {
      valid = false;
      errorsTemp["stock"] = "Stock is required";
    }
    if (!stock) {
      valid = false;
      errorsTemp["stock"] = "Stock is required";
    }

    if (brand?.length === 0) {
      valid = false;
      errorsTemp["brand"] = "Brand is required";
    }

    if (categoryValue?.length === 0) {
      valid = false;
      errorsTemp["categoryValue"] = "Category is required";
    }

    setErrors(errorsTemp);
    return valid;
  };

  const rename = (({label: name, ...rest}) => ({name, ...rest}))

  const addNewProduct = async (e) => {
    setProgressing(true);
    e.preventDefault();
    if (validate()) {
      let updatedCategoryValue = rename(categoryValue);
      let body;
      if(defectOrClearancePrice){
        body = {
          brands: brand,
          categories: [updatedCategoryValue],
          name,
          description,
          price,
          defectOrClearancePrice,
          stock,
          isAvailable,
          isDeleted: false,
          isPopular,
          isInSlideShow: inSlideShow,
          isDefect,
          isClearance,
          slug: "test",
        };
      } else{
        body = {
          brands: brand,
          categories: [updatedCategoryValue],
          name,
          description,
          price,
          stock,
          isAvailable,
          isDeleted: false,
          isPopular,
          isInSlideShow: inSlideShow,
          isDefect: false,
          isClearance: false,
          slug: "test",
        };
      }

      try {
        const newProduct = await addNewProductApi(body, selectedImagesToUpload);

        setProductList([...productList, newProduct]);
        setFilteredProductList([...productList, newProduct]);

        setName("");
        setDescription("");
        setPrice("");
        setDefectOrClearancePrice("");
        setStock("");
        setImageUrl("");
        setCategoryValue([]);
        setBrand([]);

        setSelectedImagesToUpload([]);

        onClose();
        setProgressing(false);
      } catch (error) {
        onClose();
        setProgressing(false);
        return;
      }
    } else {
      setProgressing(false);
    }
  };

  const nameOnChange = (event) => {
    setName(event.target.value);
    if (errors["name"]) {
      setErrors({ ...errors, name: null });
    }
  };

  const descriptionOnChange = (event) => {
    setDescription(event.target.value);
    if (errors["description"]) {
      setErrors({ ...errors, description: null });
    }
  };

  const priceOnChange = (event) => {
    setPrice(event.target.value);
    if (errors["price"]) {
      setErrors({
        ...errors,
        price: validatePrice(parseInt(event.target.value)),
      });
    }
  };

  const defectOrClearancePriceOnChange = (event) => {
    setDefectOrClearancePrice(event.target.value);
    if (errors["defectOrClearancePrice"]) {
      setErrors({
        ...errors,
        defectOrClearancePrice: validateDefectOrClearancePrice(
          parseInt(event.target.value)
        ),
      });
    }
  };

  const stockOnChange = (event) => {
    setStock(event.target.value);
    if (errors["stock"]) {
      setErrors({
        ...errors,
        stock: validateStock(parseInt(event.target.value)),
      });
    }
  };

  const handleIsAvailableChange = (event) => {
    setIsAvailable(event.target.value === "true" ? true : false);
  };

  const handleIsPopularChange = (event) => {
    setIsPopular(event.target.value === "true" ? true : false);
  };

  const handleInSlideShowChange = (event) => {
    setInSlideShow(event.target.value === "true" ? true : false);
  };

  const handleisDefectChange = (event) => {
    setIsDefect(event.target.value === "true" ? true : false);
  };

  const handleIsClearanceChange = (event) => {
    setIsClearance(event.target.value === "true" ? true : false);
  };

  const validatePrice = (number) => {
    let result = "";
    if (!number || number === "") {
      result = "Price is required";
    } else {
      var patt = new RegExp("[^0-9]");
      if (patt.test(number)) {
        // contains special chars
        result = "Price should be a number";
      } else {
        if (number < 0) {
          // contains special chars
          result = "Price should greater than 0";
        }
      }
    }
    return result;
  };
  const validateDefectOrClearancePrice = (number) => {
    let result = "";
    if(number) {
      var patt = new RegExp("[^0-9]");
      if (patt.test(number)) {
        // contains special chars
        result = "DefectOrClearancePrice should be a number";
      } else {
        if (number < 0) {
          // contains special chars
          result = "DefectOrClearancePrice should greater than 0";
        }
      }
    }
    return result;
  };
  const validateStock = (number) => {
    let result = "";
    if (!number || number === "") {
      result = "Stock is required";
    } else {
      var patt = new RegExp("[^0-9]");
      if (patt.test(number)) {
        // contains special chars
        result = "Stock should be a number";
      } else {
        if (number < 0) {
          // contains special chars
          result = "Stock should greater than 0";
        }
      }
    }
    return result;
  };

  if (progressing) {
    return (
      <div style={{ margin: "0 auto" }}>
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    );
  } else {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Scrollbars style={{ height: 500 }}>
          <Fade in={open}>
            <GridContainer>
              <GridItem xs={24} sm={24} md={18} style={{ width: "100%" }}>
                <Card>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Create Product</h4>
                    <p className={classes.cardCategoryWhite}>
                      Enter Product Details
                    </p>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Name"
                          id="Name"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={name}
                          onChange={nameOnChange}
                          error={errors["name"] ? true : false}
                          errorHelperText={errors["name"]}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Description"
                          id="description"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={description}
                          onChange={descriptionOnChange}
                          error={errors["description"] ? true : false}
                          errorHelperText={errors["description"]}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Price"
                          id="Price"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={price}
                          onChange={priceOnChange}
                          error={errors["price"] ? true : false}
                          errorHelperText={errors["price"]}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="DefectOrClearancePrice"
                          id="DefectOrClearancePrice"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={defectOrClearancePrice}
                          onChange={defectOrClearancePriceOnChange}
                          error={
                            errors["defectOrClearancePrice"] ? true : false
                          }
                          errorHelperText={errors["defectOrClearancePrice"]}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Stock "
                          id="Stock "
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={stock}
                          onChange={stockOnChange}
                          error={errors["stock"] ? true : false}
                          errorHelperText={errors["stock"]}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <br />
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <Autocomplete
                          disablePortal
                          value={categoryValue}
                          onChange={(event, newValue) => {
                            setBrand([]);
                            if(newValue === null){
                              setCategoryValue([]);
                              setBrandCategories([]);
                            }
                            else{
                              setCategoryValue(newValue);
                              setBrandCategories(newValue?.brands)
                            }                          
                            if (errors["categoryValue"]) {
                              setErrors({ ...errors, categoryValue: null });
                            }
                          }}
                          id="combo-box-demo"
                          getOptionLabel={(option) => option.label || ""}
                          options={categoryListDropDown}
                          style={{ width: "80%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Category"
                              placeholder="Category"
                              error={errors["categoryValue"] ? true : false}
                              variant="outlined"
                              helperText={errors["categoryValue"]}
                            />
                          )}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Autocomplete
                          value={brand}
                          onChange={(event, newValue) => {
                            setBrand(newValue);
                            if (errors["brand"]) {
                              setErrors({ ...errors, brand: null });
                            }
                          }}
                          multiple
                          id="checkboxes-tags-demo"
                          options={brandCategories}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option?.name}
                            </li>
                          )}
                          style={{ width: "80%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Brands"
                              placeholder="Brands"
                              variant="outlined"
                              error={errors["brand"] ? true : false}
                              helperText={errors["brand"]}
                            />
                          )}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <Autocomplete
                          value={categoryValue}
                          onChange={(event, newValue) => {
                            setCategoryValue(newValue);
                            // console.log("selected cat>>>", newValue);
                            // let categoryBrands = [];
                            // for(let i = 0; i < newValue?.length; i++){
                            //   for(let j = 0; j < newValue[i].brands; j++){
                            //     categoryBrands.push(newValue[i].brands[j].name)
                            //   }
                            // }
                            // // setBrandCategories(newValue)
                            setBrandCategories(newValue[0].brands)
                            if (errors["categoryValue"]) {
                              setErrors({ ...errors, categoryValue: null });
                            }
                          }}
                          // inputValue={inputValue}
                          // onInputChange={(event, newInputValue) => {
                          //   setInputValue(newInputValue);
                          // }}
                          multiple
                          id="checkboxes-tags-demo"
                          options={categoryListDropDown}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option?.name}
                            </li>
                          )}
                          style={{ width: "80%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Category"
                              placeholder="Category"
                              error={errors["categoryValue"] ? true : false}
                              variant="outlined"
                              helperText={errors["categoryValue"]}
                            />
                          )}
                        />
                      </GridItem> */}
                    </GridContainer>
                    <br />
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={2}
                        style={{ display: "flex", margin: "auto" }}
                      >
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            Is Available
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="true"
                            name="radio-buttons-group"
                            value={isAvailable}
                            onChange={handleIsAvailableChange}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="True"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="False"
                            />
                          </RadioGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            Is Popular
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={true}
                            name="radio-buttons-group"
                            value={isPopular}
                            onChange={handleIsPopularChange}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="True"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="False"
                            />
                          </RadioGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            In SlideShow
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={true}
                            name="radio-buttons-group"
                            value={inSlideShow}
                            onChange={handleInSlideShowChange}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="True"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="False"
                            />
                          </RadioGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            Is Defect{" "}
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={true}
                            name="radio-buttons-group"
                            value={isDefect}
                            onChange={handleisDefectChange}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="True"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="False"
                            />
                          </RadioGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            Is Clearance
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={true}
                            name="radio-buttons-group"
                            value={isClearance}
                            onChange={handleIsClearanceChange}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="True"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="False"
                            />
                          </RadioGroup>
                        </FormControl>
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <MultipleImageUploader
                          error={errors["imageUrl"] ? true : false}
                          errorHelperText={errors["imageUrl"]}
                          setSelectedImagesToUpload={setSelectedImagesToUpload}
                          setImagesList={setImagesList}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" onClick={addNewProduct}>
                      Create Product
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </Fade>
        </Scrollbars>
      </Modal>
    );
  }
}

CreateProductModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  productList: PropTypes.array,
  setProductList: PropTypes.func,
  setFilteredProductList: PropTypes.func,
};
