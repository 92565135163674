import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CreateProductModal from "./CreateProductModal";
import UpdateProductModal from "./UpdateProductModal";
import ProductsTable from "components/Table/ProductsTable";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Scrollbars from "react-custom-scrollbars";
import { getAllProductsApi } from "API/product.api";
import { deleteProductApi } from "API/product.api";
import { getAllCategoriesApi } from "API/category.api";
import { getAllBrandsApi } from "API/brand.api";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  createMenu: {
    marginLeft: "2%",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  ...styles,
  headerRoot: {
    display: "flex",
  },
  headerTitle: {
    flexGrow: "2",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    flexGrow: "1",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: (theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: (theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Offer() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [offerNullMenuList, setOfferNullMenuList] = useState([]);
  const [menuHashMap, setMenuHashMap] = useState({});
  const [productList, setProductList] = useState([]);
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [searched, setSearched] = useState("");
  const [selectedProduct, setSelectedProduct] = useState({});
  const [categoryListDropDown, setCategoryListDropDown] = useState([]);
  const [brandListDropDown, setBrandListDropDown] = useState([]);
  const [selectedImagesToUpload, setSelectedImagesToUpload] = useState([]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImagesToUpload([]);
  };
  const handleOpenUpdateModal = () => {
    setOpenUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setOpenUpdateModal(false);
    setSelectedImagesToUpload([]);
  };

  const requestSearch = (searchValue) => {
    const filteredRows = productList.filter((row) => {
      return row.name.toLowerCase().includes(searchValue.toLowerCase());
    });
    setFilteredProductList(filteredRows);
  };
  const onChangeSearch = (searchedValue) => {
    setSearched(searchedValue);
  };
  const deleteProduct = async (product) => {
    const deletedProduct = await deleteProductApi(product).then((res) => {
      const newProductList = productList.filter(
        (singleProduct) => singleProduct.id !== product?.id
      );
      setProductList([...newProductList]);
      setFilteredProductList([...newProductList]);
    }).catch((err) => {
      console.log("Error deleting product>>", err);
    });

  };

  useEffect(async () => {
    try {
      const products = await getAllProductsApi();

      setFilteredProductList(products);
      setProductList(products);
    } catch (error) {
      setFilteredProductList([]);
      setProductList([]);
    }
  }, []);

  useEffect(async () => {
    try {
      const categories = await getAllCategoriesApi();
      let catDropDown = [];
      categories.forEach(element => {
        catDropDown.push({
          label: element?.name,
          id: element?.id,
          brands: element?.brands,
          created_at: element?.created_at,
          slug: element?.slug,
          updated_at: element?.updated_at
        })
      });
      setCategoryListDropDown(catDropDown);
    } catch (error) {
      setCategoryListDropDown([]);
      setBrandListDropDown([]);
    }
  }, []);

  useEffect(async () => {
    try {
      const brands = await getAllBrandsApi();
      setBrandListDropDown(brands);
    } catch (error) {
      setBrandListDropDown([]);
    }
  }, []);

  return (
    <GridContainer>
      <CreateProductModal
        open={open}
        onClose={handleClose}
        menuList={menuList}
        offerNullMenuList={offerNullMenuList}
        productList={productList}
        setProductList={setProductList}
        setFilteredProductList={setFilteredProductList}
        menuHashMap={menuHashMap}
        brandListDropDown={brandListDropDown}
        categoryListDropDown={categoryListDropDown}
        selectedImagesToUpload={selectedImagesToUpload}
        setSelectedImagesToUpload={setSelectedImagesToUpload}
        disableEnforceFocus
      />
      <UpdateProductModal
        open={openUpdateModal}
        onClose={handleCloseUpdateModal}
        productList={productList}
        setProductList={setProductList}
        setFilteredProductList={setFilteredProductList}
        product={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        menuList={menuList}
        offerNullMenuList={offerNullMenuList}
        menuHashMap={menuHashMap}
        brandListDropDown={brandListDropDown}
        categoryListDropDown={categoryListDropDown}
        selectedImagesToUpload={selectedImagesToUpload}
        setSelectedImagesToUpload={setSelectedImagesToUpload}
      />
      <Button
        className={classes.createMenu}
        onClick={handleOpen}
        color="primary"
      >
        Create Product
      </Button>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.headerRoot}>
              <div className={classes.headerTitle}>
                <h4 className={classes.cardTitleWhite}>Products Table</h4>
                <p className={classes.cardCategoryWhite}>List of all products</p>
              </div>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Product Name..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={searched}
                  onChange={(e) => {
                    e.preventDefault();
                    onChangeSearch(e.target.value);
                    requestSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </CardHeader>
          <Scrollbars style={{ height: 500 }}>
            <CardBody>
              <ProductsTable
                tableHeaderColor="primary"
                tableHead={[
                  "Name",
                  "Category",
                  "Price",
                  "Stock",
                  "DefectOrClearancePrice",
                  "IsAvailable",
                  "isPopular ",
                  "isInSlideShow",
                  "isDefect",
                  "isClearance",
                  "Action"
                ]}
                tableData={filteredProductList}
                deleteProduct={deleteProduct}
                setSelectedProduct={setSelectedProduct}
                handleOpenUpdateModal={handleOpenUpdateModal}
                selectedProduct={selectedProduct}
                setProductList={setProductList}
                setFilteredProductList={setFilteredProductList}
              />
            </CardBody>
          </Scrollbars>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
