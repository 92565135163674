import { addNewBulkImagesApi } from "API/image.api";
import S3FileUpload from "react-s3";
import { verifyJwtToken } from "util/JwtExpireHelper";
import { v4 as uuidv4 } from 'uuid';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
const S3_BUCKET_REGION = process.env.REACT_APP_S3_BUCKET_REGION;
const SAK = process.env.REACT_APP_SAK;
const AKI = process.env.REACT_APP_AKI;

export async function s3ImageUpload(selectedImagesToUpload, imagesType) {
  let imagesInput = [];

  let uniqueImageId = uuidv4();

  const mdconfig = {
    bucketName: S3_BUCKET,
    dirName: `carry-on-sri-lanka/md/${uniqueImageId}` /* optional */,
    region: S3_BUCKET_REGION,
    accessKeyId: AKI,
    secretAccessKey: SAK,
  };

  const lgconfig = {
    bucketName: S3_BUCKET,
    dirName: `carry-on-sri-lanka/lg/${uniqueImageId}` /* optional */,
    region: S3_BUCKET_REGION,
    accessKeyId: AKI,
    secretAccessKey: SAK,
  };

  const smconfig = {
    bucketName: S3_BUCKET,
    dirName: `carry-on-sri-lanka/sm/${uniqueImageId}` /* optional */,
    region: S3_BUCKET_REGION,
    accessKeyId: AKI,
    secretAccessKey: SAK,
  };

  let jwtResponse = await verifyJwtToken();

  if (jwtResponse) {
    if (selectedImagesToUpload?.length > 0) {
      for (let i = 0; i < selectedImagesToUpload?.length; i++) {
        await S3FileUpload.uploadFile(
          selectedImagesToUpload[i]?.resizedlg,
          lgconfig
        )
          .then((data) => {
            imagesInput.push({
              s3Url: data?.location,
              type: imagesType,
              size: "large",
              imageSizesId: selectedImagesToUpload[i].imagesSizeId,
            });
          })
          .catch((err) => console.error(err));

        await S3FileUpload.uploadFile(
          selectedImagesToUpload[i]?.resizedmd,
          mdconfig
        )
          .then((data) => {
            imagesInput.push({
              s3Url: data?.location,
              type: imagesType,
              size: "medium",
              imageSizesId: selectedImagesToUpload[i].imagesSizeId,
            });
          })
          .catch((err) => console.error(err));

        await S3FileUpload.uploadFile(
          selectedImagesToUpload[i]?.resizedsm,
          smconfig
        )
          .then((data) => {
            imagesInput.push({
              s3Url: data?.location,
              type: imagesType,
              size: "small",
              imageSizesId: selectedImagesToUpload[i].imagesSizeId,
            });
          })
          .catch((err) => console.error(err));
      }
    }

    if (imagesInput?.length > 0) {
      try {
        const addBulkImageData = await addNewBulkImagesApi(imagesInput);
        if (addBulkImageData) {
          return Promise.resolve(addBulkImageData);
        }
      } catch (err) {
        getToast(false, "Error creating brand");
        return Promise.reject();
      }
    }
  }
}
