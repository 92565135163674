import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Scrollbars from "react-custom-scrollbars";
import Fade from "@material-ui/core/Fade";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CustomInput from "components/CustomInput/CustomInput";
import CardFooter from "components/Card/CardFooter";
import Loader from "react-loader-spinner";
import { useEffect, useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody";
import {
  Autocomplete,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { addNewDeliverySettingApi } from "API/deliverysettings.api";
import { editDeliverySettingApi } from "API/deliverysettings.api";



const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content",
    margin: "10%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

export default function CreateDeliverySettingsModal({
  type,
  data,
  open,
  onClose,
  onCreateSuccess,
  onEditSuccess,
  shippingAreas,
}) {
  const classes = useStyles();

  const [values, setValues] = useState({
    cost: 0,
    shippingArea: null,
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [progressing, setProgressing] = useState(false);
  
  useEffect(() => {
    if (type === "edit" && data) {
      console.log(data)
      setValues({
        cost: data.cost,
        shippingArea: data.shippingArea,
        message: data.message,
      })
    } else {
      setValues({
        cost: 0,
        shippingArea: null,
        message: "",
      })
    }
  }, [data, type])

  const onChange = (event, target) => {
    setValues({ ...values, [target]: event.target.value })
  }

  if (progressing) {
    return (
      <div style={{ margin: "0 auto" }}>
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    );
  }

  const validate = () => {
    const errors = {};
    if (!values.cost) {
      errors.cost = "Cost is required";
    }
    if (!values.shippingArea) {
      errors.shippingArea = "Shipping Area is required";
    }
    if (!values.message) {
      errors.message = "Message is required";
    }
    setErrors(errors);

    if (Object.keys(errors).length > 0) {
      setProgressing(false);
      return false;
    }

    return true;
  }

  const handleSubmit = async () => {
    setProgressing(true);

    if (type === "create") {
      if (validate()) {
        try {
          const newDeliverySetting = await addNewDeliverySettingApi({
            cost: values.cost,
            shippingArea: values.shippingArea.value,
            message: values.message,
          })
          setProgressing(false);
          onCreateSuccess(newDeliverySetting);
        } catch (error) {
          setProgressing(false);
          console.log(error)
          return;
        }
      } else {
        setProgressing(false);
      }
    } else {
      if (validate()) {
        try {
          const updatedDeliverySetting = await editDeliverySettingApi({
            id: data.id,
            cost: values.cost,
            shippingArea: values.shippingArea.value,
            message: values.message,
          })
          setProgressing(false);
          onEditSuccess(updatedDeliverySetting);
        } catch (error) {
          setProgressing(false);
          console.log(error)
          return;
        }
      } else {
        setProgressing(false);
      }
    }

    setValues({
      cost: 0,
      shippingArea: null,
      message: "",
    })

  }

  console.log(values)

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Scrollbars style={{ height: 500 }}>
        <Fade in={open}>
          <GridContainer>
            <GridItem xs={24} sm={24} md={18} style={{ width: "100%" }}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Create Site Setting</h4>
                  <p className={classes.cardCategoryWhite}>
                    Enter Delivery Setting Details
                  </p>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                    <Autocomplete
                          disablePortal
                          value={values.shippingArea}
                          onChange={(event, newValue) => {
                            setValues({ ...values, shippingArea: newValue });
                          }}
                          id="combo-box-demo"
                          getOptionLabel={(option) => option.key || ""}
                          options={shippingAreas}
                          style={{ width: "80%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Shipping Area"
                              placeholder="Shipping Area"
                              error={errors["shippingArea"] ? true : false}
                              variant="outlined"
                              helperText={errors["shippingArea"]}
                            />
                          )}
                        />
                      </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Cost"
                        type="number"
                        id="Cost"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={values.cost}
                        onChange={(e) => onChange(e, "cost")}
                        error={errors["cost"] ? true : false}
                        errorHelperText={errors["cost"]}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Message"
                        id="message"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={values.message}
                        onChange={(e) => onChange(e, "message")}
                        error={errors["message"] ? true : false}
                        errorHelperText={errors["message"]}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button color="primary" onClick={handleSubmit}>
                    {type === "create" ? "Create" : "Edit" } Delivery Setting
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </Fade>
      </Scrollbars>
    </Modal>
  );
}