/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';
import FontDownloadOutlinedIcon from '@mui/icons-material/FontDownloadOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';

// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import Customer from "views/Customer/Customer.js";
import SiteSettings from "views/SiteSettings/SiteSettings.js";
import Order from "views/Order/Order";
import Category from "views/Categories/Category";
import Product from "views/Products/Product";
import Review from "views/Reviews/Review";
import Brand from "views/Brands/Brand";
import AdminUser from "views/AdminUser/AdminUser";
import PromoCodes from "views/PromoCodes/PromoCodes.js";
import DeliverySettings from "views/DeliverySettings/DeliverySettings";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Products",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Inventory2OutlinedIcon,
    component: Product,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: Order,
    layout: "/admin",
  },
  {
    path: "/brands",
    name: "Brands",
    rtlName: "ملف تعريفي للمستخدم",
    icon: FontDownloadOutlinedIcon,
    component: Brand,
    layout: "/admin",
  },
  {
    path: "/category",
    name: "Category",
    rtlName: "الرموز",
    icon: FilterNoneOutlinedIcon,
    component: Category,
    layout: "/admin",
  },
  {
    path: "/reviews",
    name: "Reviews",
    rtlName: "ملف تعريفي للمستخدم",
    icon: RateReviewOutlinedIcon,
    component: Review,
    layout: "/admin",
  },
  {
    path: "/promocodes",
    name: "Promo Codes",
    rtlName: "الرموز",
    icon: DiscountOutlinedIcon,
    component: PromoCodes,
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "Customers",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: Customer,
    layout: "/admin",
  },
  {
    path: "/admin",
    name: "Admins",
    rtlName: "ملف تعريفي للمستخدم",
    icon: AdminPanelSettingsOutlinedIcon,
    component: AdminUser,
    layout: "/admin",
  },
  {
    path: "/sitesettings",
    name: "Site Settings",
    rtlName: "الرموز",
    icon: SettingsOutlinedIcon,
    component: SiteSettings,
    layout: "/admin",
  },
  {
    path: "/deliverysettings",
    name: "Delivery Settings",
    rtlName: "الرموز",
    icon: SettingsOutlinedIcon,
    component: DeliverySettings,
    layout: "/admin",
  },
];

export default dashboardRoutes;
