import React, { useEffect, useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Resizer from "react-image-file-resizer";
import Button from "@mui/material/Button";
import CustomInput from "components/CustomInput/CustomInput";
import Snackbar from "components/Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";
import { v4 as uuidv4 } from 'uuid';

const MultipleImageUploader = ({
  dataSources,
  setSelectedImagesToUpload,
  setImagesList,
  error,
  errorHelperText,
}) => {
  const [selectedImages, setSelectedImages] = useState(
    dataSources?.length > 0 ? dataSources : []
  );
  const [selectedFileList, setSelectedFileList] = useState([]);
  const [tc, setTC] = useState(false);
  const [imagesToShow, setImagesToShow] = useState([]);

  const smresizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          console.log("URI>>>>", uri);
          resolve(uri);
        },
        "file"
      );
    });

  const mdresizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          console.log("URI>>>>", uri);
          resolve(uri);
        },
        "file"
      );
    });

  const lgresizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        700,
        700,
        "JPEG",
        100,
        0,
        (uri) => {
          console.log("URI>>>>", uri);
          resolve(uri);
        },
        "file"
      );
    });

  const showNotification = (place) => {
    switch (place) {
      case "tc":
        if (!tc) {
          setTC(true);
          setTimeout(function () {
            setTC(false);
          }, 6000);
        }
        break;
      default:
        break;
    }
  };

  const onSelectFile = async (event) => {
    const selectedFiles = event.target.files;
    const uploadedFile = event.target.files[0];

    if (
      uploadedFile?.type === "image/jpeg" ||
      uploadedFile?.type === "image/png" ||
      uploadedFile?.type === "image/jpg"
    ) {
      const selectedFilesArray = Array.from(selectedFiles);

      const imagesArray = selectedFilesArray.map((file) => {
        return URL.createObjectURL(file);
      });

      const resizedsm = await smresizeFile(uploadedFile);

      const resizedmd = await mdresizeFile(uploadedFile);

      // const resizedlg = await lgresizeFile(uploadedFile);

      const url = URL.createObjectURL(uploadedFile);

      let imagesSizeId = uuidv4();

      selectedFileList.push({
        resizedsm,
        resizedmd,
        resizedlg: uploadedFile,
        url: imagesArray[0],
        imagesSizeId
      });

      setSelectedImagesToUpload(selectedFileList);

      let newSelectedImage = {
        id: Date.now(),
        s3Url: imagesArray[0],
        action: "added",
      };

      // setSelectedImages((previousImages) => previousImages.concat(imagesArray));
      setSelectedImages((previousImages) => [
        ...previousImages,
        newSelectedImage,
      ]);

      setImagesToShow((previousImages) => [
        ...previousImages,
        newSelectedImage,
      ]);
    } else {
      showNotification("tc");
    }

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    if(image?.imageSizesId){
      setImagesToShow(imagesToShow.filter((e) => e?.imageSizesId !== image?.imageSizesId));
      setSelectedImages(selectedImages.filter((e) => e?.imageSizesId !== image?.imageSizesId));
      setImagesList(selectedImages.filter((e) => e?.imageSizesId !== image?.imageSizesId));
    }
    else{
      setImagesToShow(imagesToShow.filter((e) => e?.id !== image?.id));
      setSelectedImages(selectedImages.filter((e) => e?.id !== image?.id));
      setImagesList(selectedImages.filter((e) => e?.id !== image?.id));
    }
    setSelectedFileList(
      selectedFileList.filter((e) => e?.url !== image?.s3Url)
    );
    setSelectedImagesToUpload(
      selectedFileList.filter((e) => e?.url !== image?.s3Url)
    );

    URL.revokeObjectURL(image?.s3Url);
  }

  useEffect(async () => {
    setSelectedImages(dataSources?.length > 0 ? dataSources : []);
    setImagesToShow(dataSources?.length > 0 ? dataSources?.filter((e) => e?.size === "large") : []);
    // setImagesList(dataSources?.length > 0 ? dataSources: []);
  }, [dataSources]);

  return (
    <section>
      <React.Fragment>
        <Snackbar
          place="tc"
          color="danger"
          icon={AddAlert}
          message="jpeg , png , jpg are the only allowed file types"
          open={tc}
          closeNotification={() => setTC(false)}
          close
        />
        <CustomInput
          labelText="Add Image"
          id="image"
          formControlProps={{
            fullWidth: false,
          }}
          type="file"
          onChange={onSelectFile}
          error={error}
          errorHelperText={errorHelperText}
        />
      </React.Fragment>
      <br />

      <div>
        <ImageList variant="masonry" cols={3} gap={8}>
          {imagesToShow?.map((image, index) => (
            <ImageListItem key={image?.id}>
              <img
                src={`${image?.s3Url}`}
                // srcSet={`${image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                // alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                // title={item.title}
                // subtitle={item.author}
                sx={{
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                }}
                position="top"
                actionIcon={
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => deleteHandler(image)}
                  >
                    Delete
                  </Button>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </section>
  );
};

export default MultipleImageUploader;
