import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { editCategoryApi } from "API/category.api";
import Scrollbars from "react-custom-scrollbars";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import MultipleImageUploader from "components/MultipleImageUploader/MultipleImageUploader";
import { getAllCategoriesApi } from "API/category.api";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content",
    margin: "10%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

export default function UpdateCategoryModal({
  open,
  onClose,
  category,
  brandListDropDown,
  setCategoryList,
  setFilteredCategoryList,
  categoryList,
  selectedImagesToUpload,
  setSelectedImagesToUpload,
}) {
  const classes = useStyles();
  const [progressing, setProgressing] = useState(false);
  const [name, setName] = useState("");
  const [brand, setBrand] = useState(null);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  // const [selectedImagesToUpload, setSelectedImagesToUpload] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [updateInputBody, setUpdateInputBody] = useState({});
  const [initialBrands, setInitialBrands] = useState([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const validate = () => {
    let valid = true;
    let errorsTemp = {};
    setUpdateInputBody({});
    updateInputBody.id = category?.id;

    if (!name) {
      valid = false;
      setError("Name is required");
    }

    if (name !== category?.name) {
      if (
        categoryList.some((el) => el?.name.toLowerCase() === name.toLowerCase())
      ) {
        valid = false;
        setError("A Category with the same name already exists.");
      } else {
        updateInputBody.name = name;
      }
    }

    let brandChange = JSON.stringify(brand) == JSON.stringify(initialBrands);

    if (!brandChange) {
      updateInputBody.brands = brand;
    }

    if (brand?.length === 0) {
      valid = false;
      errorsTemp["brand"] = "Brand is required";
    }

    var filteredImages = [];
    if (imagesList?.length > 0) {
      filteredImages = imagesList.filter((e) => e?.action !== "added");
      updateInputBody.images = filteredImages;
    }

    if (filteredImages?.length === 0) {
      updateInputBody.images = [];
    }

    if (filteredImages?.length === 0 && selectedImagesToUpload?.length === 0) {
      valid = false;
      errorsTemp["imageUrl"] = "Image is required";
    }

    setErrors(errorsTemp);
    return valid;
  };

  const editCategory = async (e) => {
    setProgressing(true);
    e.preventDefault();
    if (validate()) {
      try {
        const newCategory = await editCategoryApi(
          updateInputBody,
          selectedImagesToUpload
        );
        if (newCategory) {
          const category = await getAllCategoriesApi();
          if (category) {
            setCategoryList(category);
            setFilteredCategoryList(category);
            setBrand(product?.brands);
            setInitialBrands(product?.brands);
            setSelectedImagesToUpload([]);
            onClose();
            setProgressing(false);
          }
        }
      } catch (error) {
        setSelectedImagesToUpload([]);
        onClose();
        setProgressing(false);
        return;
      }
    } else {
      setProgressing(false);
    }
  };

  const nameOnChange = (event) => {
    setName(event.target.value);
    if (error) {
      setError("");
    }
  };

  useEffect(() => {
    setName(category?.name);

    let imageArray = [];

    if (category?.images?.length > 0) {
      for (let i = 0; i < category?.images?.length; i++) {
        imageArray.push(category?.images[i].s3Url);
      }
    }

    setBrand(category?.brands);
    setInitialBrands(category?.brands);

    setImagesList(category?.images);

    setDataSources(category?.images);
  }, [category]);

  if (progressing) {
    return (
      <div style={{ margin: "0 auto" }}>
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    );
  } else {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Scrollbars style={{ height: 500 }}>
          <Fade in={open}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ width: "100%" }}>
                <Card>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Edit Category</h4>
                    <p className={classes.cardCategoryWhite}>
                      Edit category Details
                    </p>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Name"
                          id="Name"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={name}
                          onChange={nameOnChange}
                          error={error ? true : false}
                          errorHelperText={error}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      {brand != null &&
                      <GridItem xs={12} sm={12} md={6}>
                        <Autocomplete
                          value={brand}
                          onChange={(event, newValue) => {
                            setBrand(newValue);
                            if (errors["brand"]) {
                              setErrors({ ...errors, brand: null });
                            }
                          }}
                          multiple
                          id="checkboxes-tags-demo"
                          options={brandListDropDown}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option?.name}
                          isOptionEqualToValue={(option, value) =>
                            option.name === value.name
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option?.name}
                            </li>
                          )}
                          style={{ width: "80%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Brands"
                              placeholder="Brands"
                              error={errors["brand"] ? true : false}
                              variant="outlined"
                              helperText={errors["brand"]}
                            />
                          )}
                        />
                      </GridItem>
                    }
                    </GridContainer>
                    <GridContainer>
                      <GridItem>
                        <MultipleImageUploader
                          error={errors["imageUrl"] ? true : false}
                          errorHelperText={errors["imageUrl"]}
                          dataSources={dataSources}
                          setSelectedImagesToUpload={setSelectedImagesToUpload}
                          setImagesList={setImagesList}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Button block color="primary" onClick={editCategory}>
                      Edit category
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </Fade>
        </Scrollbars>
      </Modal>
    );
  }
}

UpdateCategoryModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  category: PropTypes.object,
  setCategoryList: PropTypes.func,
  setFilteredCategoryList: PropTypes.func,
  categoryList: PropTypes.array,
};
