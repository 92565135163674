import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import MultipleImageUploader from "components/MultipleImageUploader/MultipleImageUploader";
import { editBrandApi } from "API/brand.api";
import { getAllBrandsApi } from "API/brand.api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content",
    margin: "10%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  image: {
    width: 256,
    height: 150,
  },
  formControl: {
    marginTop: "10%",
    minWidth: 60,
  },
}));

export default function UpdateBrandModal({
  open,
  onClose,
  brandList,
  setBrandList,
  setFilteredBrandList,
  brand,
  selectedImagesToUpload,
  setSelectedImagesToUpload,
}) {
  const classes = useStyles();
  const [progressing, setProgressing] = useState(false);
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});
  // const [selectedImagesToUpload, setSelectedImagesToUpload] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [updateInputBody, setUpdateInputBody] = useState({});

  const validate = () => {
    let valid = true;
    let errorsTemp = {};
    setUpdateInputBody({});
    updateInputBody.id = brand?.id;

    if (!name) {
      valid = false;
      errorsTemp["name"] = "Name is required";
    }

    if (name !== brand?.name) {
      if (
        brandList.some((el) => el?.name.toLowerCase() === name.toLowerCase())
      ) {
        valid = false;
        errorsTemp["name"] = "A Brand with the same name already exists.";
      } else {
        updateInputBody.name = name;
      }
    }

    var filteredImages = [];
    if (imagesList?.length > 0) {
      filteredImages = imagesList.filter((e) => e?.action !== "added");
      updateInputBody.images = filteredImages;
    }

    if (filteredImages?.length === 0) {
      updateInputBody.images = [];
    }

    if (filteredImages?.length === 0 && selectedImagesToUpload?.length === 0) {
      valid = false;
      errorsTemp["imageUrl"] = "Image is required";
    }

    setErrors(errorsTemp);
    return valid;
  };

  const editBrand = async (e) => {
    setProgressing(true);
    e.preventDefault();
    if (validate()) {
      try {
        const newBrand = await editBrandApi(
          updateInputBody,
          selectedImagesToUpload
        );
        if (newBrand) {
          const brands = await getAllBrandsApi();
          if (brands) {
            setBrandList(brands);
            setFilteredBrandList(brands);
            onClose();
            setProgressing(false);
          }
        }
      } catch (error) {
        onClose();
        setProgressing(false);
        return;
      }
    } else {
      setProgressing(false);
    }
  };

  const nameOnChange = (event) => {
    setName(event.target.value);
    if (errors["name"]) {
      setErrors({ ...errors, name: null });
    }
  };

  useEffect(() => {
    if (brand) {
      setName(brand?.name);
      setImagesList(brand?.images);
      setDataSources(brand?.images);
    }
  }, [brand]);

  if (progressing) {
    return (
      <div style={{ margin: "0 auto" }}>
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    );
  } else {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Scrollbars style={{ height: 500 }}>
          <Fade in={open}>
            <GridContainer>
              <GridItem xs={24} sm={24} md={18} style={{ width: "100%" }}>
                <Card>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Edit Brand</h4>
                    <p className={classes.cardCategoryWhite}>
                      Edit Brand Details
                    </p>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Name"
                          id="Name"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={name}
                          onChange={nameOnChange}
                          error={errors["name"] ? true : false}
                          errorHelperText={errors["name"]}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem>
                        <MultipleImageUploader
                          error={errors["imageUrl"] ? true : false}
                          errorHelperText={errors["imageUrl"]}
                          dataSources={dataSources}
                          setSelectedImagesToUpload={setSelectedImagesToUpload}
                          imagesList={imagesList}
                          setImagesList={setImagesList}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" onClick={editBrand}>
                      Update Brand
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </Fade>
        </Scrollbars>
      </Modal>
    );
  }
}

UpdateBrandModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  menuList: PropTypes.array,
  offerNullMenuList: PropTypes.array,
  brandList: PropTypes.array,
  setBrandList: PropTypes.func,
  setFilteredBrandList: PropTypes.func,
  brand: PropTypes.object,
  menuHashMap: PropTypes.object,
};
