import axios from "axios";
import { verifyJwtToken } from "util/JwtExpireHelper.js";
import { getToast } from "util/ToastHelper.js";

import { getLocalStorageProperty } from "../util/LocalStorageHelper.js";

const getToken = () => getLocalStorageProperty("carryon-admin-token");
const rootUriOwner = process.env.REACT_APP_API_URL_OWNER;

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = "bearer " + getToken();
  return config;
});

export const addNewPromoCodeApi = async (body) => {

  try {
    const { data } = await axios.post(`${rootUriOwner}/promo-codes`, body);

    if (data?.id) {
      getToast(true, `Promo Code added successfully`);
      return Promise.resolve(data);
    }
    getToast(false, data.data.message);
  } catch (error) {
    // getToast(false, error.response.data.message.message);
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, "Error creating Promo Code");
    return Promise.reject();
  }
};

export const getAllPromoCodesApi = async () => {
    try {
      const { data } = await axios.get(`${rootUriOwner}/promo-codes`);
  
      if (data) {
        // getToast(true, data.data.message);
        return Promise.resolve(data);
      } else {
        getToast(false, data.data.message);
        return Promise.reject([]);
      }
    } catch (error) {
      if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
        await verifyJwtToken();
      }
      getToast(false, error);
      return Promise.reject([]);
    }
};

export const getSinglePromoCodeApi = async (body) => {
    try {
      const { data } = await axios.get(
        `${rootUriOwner}/promo-codes/${body?.id}`,
        body
      );
      if (data?.id) {
        return Promise.resolve(data);
      } else {
        getToast(false, data);
        return Promise.reject({});
      }
    } catch (error) {
      if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
        await verifyJwtToken();
      }
      return Promise.reject({});
    }
};

export const deletePromoCodeApi = async ({ id }) => {
    const body = { id };
    try {
      const { data } = await axios.delete(
        `${rootUriOwner}/promo-codes/${id}`,
        body
      );
      if (data) {
        getToast(true, "Promo Code Deleted Successfully");
        return Promise.resolve(data);
      } else {
        getToast(false, data);
        return Promise.reject({});
      }
    } catch (error) {
      if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
        await verifyJwtToken();
      }
      getToast(false, error);
      return Promise.reject({});
    }
  };

  export const editPromoCodeApi = async ({ code, discount, isUsed, id }) => {
    const body = { code, discount, isUsed, id };
    try {
      const { data } = await axios.patch(
        `${rootUriOwner}/promo-codes/${id}`,
        body
      );
      if (data) {
        getToast(true, "Promo Code Updated Sucessfully");
        return Promise.resolve(data);
      } else {
        getToast(false, data.data.message);
        return Promise.reject({});
      }
    } catch (error) {
      if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
        await verifyJwtToken();
      }
      getToast(false, error);
      return Promise.reject({});
    }
  };
  