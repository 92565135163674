import { Button, Chip } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import PropTypes from "prop-types";
import React from "react";
import Switch from "@mui/material/Switch";
import { editOrderApi } from "API/order.api";
import { getOrderDetailsApi } from "API/order.api";

const label = { inputProps: { "aria-label": "Switch demo" } };

const useStyles = makeStyles(styles);

export default function OrderTable(props) {
  const classes = useStyles();
  const {
    tableHead,
    tableHeaderColor,
    tableData,
    setOrderDetails,
    setOrderTotal,
    handleOpenViewOrderModal,
    setFilteredOrderList,
    setOrderList,
    setSingleOrder,
  } = props;

  const handleViewOrder = async (id) => {
    if (id) {
      await getOrderDetailsApi({ id: id }).then((res) => {
        setOrderDetails(res?.carts);
        setOrderTotal(res?.totalAmount);
        setSingleOrder(res);
        handleOpenViewOrderModal(true);
      });
    }
  };

  const handlePaymentStatusSwitchChange = async (event) => {
    let paymentStatusInput =
      event.paymentStatus === "paid" ? "not_paid" : "paid";

    const body = {
      id: event?.id,
      paymentStatus: paymentStatusInput,
    };

    const newOrder = await editOrderApi(body);
    const newOrderList = tableData.map((cust) => {
      if (cust.id !== event.id) {
        return cust;
      } else {
        return { ...newOrder, paymentStatusInput };
      }
    });
    setOrderList([...newOrderList]);
    setFilteredOrderList([...newOrderList]);
  };

  const handleDeliveryStatusSwitchChange = async (event) => {
    let deliveryStatusInput =
      event.deliveryStatus === "delivered" ? "not_delivered" : "delivered";

    const body = {
      id: event?.id,
      deliveryStatus: deliveryStatusInput,
    };

    const newOrder = await editOrderApi(body);
    const newOrderList = tableData.map((cust) => {
      if (cust.id !== event.id) {
        return cust;
      } else {
        return { ...newOrder, deliveryStatusInput };
      }
    });
    setOrderList([...newOrderList]);
    setFilteredOrderList([...newOrderList]);
  };

  const dataRows = () => {
    return tableData.map((row, index) => (
      <TableRow key={index} className={classes.tableBodyRow}>
        <TableCell className={classes.tableCell}>{row?.id}</TableCell>
        <TableCell className={classes.tableCell}>
          {row.customer
            ? `${row?.customer?.firstName + " " + row?.customer?.lastName}`
            : `${row?.orderCustomerName}`}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {row.customer ? row?.customer?.email : row?.email}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {row.customer ? row?.customer?.phoneNumber : row?.orderPhoneNumber}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {`${row?.deliveryAddress} ${row?.town} ${row?.zip}`}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {row?.invoiceNo}
        </TableCell>
        <TableCell className={classes.tableCell}>{row?.totalAmount}</TableCell>
        <TableCell className={classes.tableCell}>
          {row?.paymentType === "cash_on_delivery" ? (
            <Chip label="Cash" color="primary" />
          ) : row?.paymentType === "bank_transfer" ? (
            <Chip label="Bank Transfer" color="secondary" />
          ) : row?.paymentType === "payable_pg" ? (
            <Chip label="Payable IPG" color="success" />
          ): (<Chip label="Invalid" color="warning" />)}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {row?.paymentStatus === "processing" ? (
            <Chip label="Processing" color="primary" />
            ) : row?.paymentStatus === "failed" ? (
              <Chip label="Failed" color="secondary" />
            ) : (
              <Switch
                {...label}
                checked={row?.paymentStatus === "paid" ? true : false}
                disabled={row?.paymentType === "payable_pg" ? true : false}
                onClick={() => handlePaymentStatusSwitchChange(row)}
                color="secondary"
              />
            )
          }
          
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Switch
            {...label}
            disabled={row?.paymentStatus === "processing" || row?.paymentStatus === "failed"  ? true : false}
            checked={row?.deliveryStatus === "delivered" ? true : false}
            onClick={() => handleDeliveryStatusSwitchChange(row)}
            color="secondary"
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          {
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleViewOrder(row?.id)}
            >
              View Order
            </Button>
          }
        </TableCell>
      </TableRow>
    ));
  };
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>{dataRows()}</TableBody>
      </Table>
    </div>
  );
}

OrderTable.defaultProps = {
  tableHeaderColor: "gray",
};

OrderTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object),

  handleOpenUpdateModal: PropTypes.func,
  deleteOffer: PropTypes.func,
  cancelOffer: PropTypes.func,
  unCancelOffer: PropTypes.func,
  setOrderDetails: PropTypes.func,
  setOrderTotal: PropTypes.func,
  handleOpenViewOrderModal: PropTypes.func,
  setFilteredOrderList: PropTypes.func,
  setOrderList: PropTypes.func,
  setSingleOrder: PropTypes.func,
};
