import { Button, IconButton, Tooltip } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Delete, Edit, Add, Block } from "@material-ui/icons";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import PropTypes from "prop-types";
import React from "react";
import Switch from "@mui/material/Switch";
import { Chip } from "@mui/material";
import { editProductApi } from "API/product.api";
import { getSingleProductApi } from "API/product.api";
import { getSinglePromoCodeApi } from "API/promocode.api";
import { editPromoCodeApi } from "API/promocode.api";

const label = { inputProps: { "aria-label": "Switch demo" } };

const useStyles = makeStyles(styles);

export default function PromoCodesTable(props) {
  const classes = useStyles();
  const {
    tableHead,
    tableHeaderColor,
    tableData,
    setSelectedPromoCode,
    handleOpenUpdateModal,
    deletePromoCode,
    setPromoCodesList,
    setFilteredPromoCodesList,
  } = props;

  const handleEdit = async (product) => {
    if(product?.id){
      await getSinglePromoCodeApi({ id: product?.id }).then((res) => {
        setSelectedPromoCode(res);
        handleOpenUpdateModal(true);
      });
    }

    // handleOpenUpdateModal(true);
    //     setSelectedPromoCode(product);
    
  };
  const handleDelete = (offer) => {
    deletePromoCode(offer);
  };

  const handleIsAvailableSwitchChange = async (event) => {
    let isAvailableInput = event.isUsed;

    let isAvailableChange = !isAvailableInput;

    const body = {
      id: event?.id,
      isUsed: isAvailableChange,
    };

    const newProduct = await editPromoCodeApi(body);
    const newProductList = tableData.map((cust) => {
      if (cust.id !== event.id) {
        return cust;
      } else {
        return { ...newProduct, isAvailableChange };
      }
    });
    setPromoCodesList([...newProductList]);
    setFilteredPromoCodesList([...newProductList]);
  };


  const dataRows = () => {
    return tableData.map((row, index) => (
      <TableRow key={index} className={classes.tableBodyRow}>
        <TableCell className={classes.tableCell}>{row?.code}</TableCell>
        <TableCell className={classes.tableCell}>{row?.discount}</TableCell>
        <TableCell className={classes.tableCell}>
          {
            <Switch
              {...label}
              checked={row?.isUsed}
              onClick={() => handleIsAvailableSwitchChange(row)}
              color="secondary"
            />
          }
        </TableCell>
        {/* <TableCell className={classes.tableCell}>{row.paymentStatus}</TableCell>
        <TableCell className={classes.tableCell}>{row.deliveryStatus}</TableCell>
        <TableCell className={classes.tableCell}>{
          <Button variant="contained" color="primary" onClick={() => handleViewOrder(row.Status)}>View Order</Button>
        }</TableCell> */}
        {/* <TableCell
          className={classes.tableCell}
        >{`${row.quantityThresh} ${row.portionThresh}`}</TableCell>
        <TableCell className={classes.tableCell}>
          {row.type === "discount" || row.type === "percentage"
            ? row.discount
            : row.type === "freeMenu" &&
              `${row?.freeMenu?.quantity} nos ${row?.freeMenu?.portion}  ${row?.freeMenu?.name}`}
        </TableCell> */}
        <TableCell className={classes.tableCell}>
          {/* {row.isAvailable ? (
            <Tooltip title="Block">
              <IconButton onClick={() => handleCancel(row)}>
                <Block fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Un-Block">
              <IconButton onClick={() => handleUnCancel(row)}>
                <Add fontSize="small" />
              </IconButton>
            </Tooltip>
          )} */}
          {/* <Tooltip title="Update Promo Code">
            <IconButton onClick={() => handleEdit(row)}>
              <Edit fontSize="small" />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Delete Promo Code">
            <IconButton onClick={() => handleDelete(row)}>
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </TableCell>
        {/* <TableCell className={classes.tableCell}>
          {row.isAvailable ? "Available" : "Not Available"}
        </TableCell> */}
      </TableRow>
    ));
  };
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>{dataRows()}</TableBody>
      </Table>
    </div>
  );
}

PromoCodesTable.defaultProps = {
  tableHeaderColor: "gray",
};

PromoCodesTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object),

  menus: PropTypes.array,
  setSelectedPromoCode: PropTypes.func,
  handleOpenUpdateModal: PropTypes.func,
  deletePromoCode: PropTypes.func,
  cancelOffer: PropTypes.func,
  unCancelOffer: PropTypes.func,
};
