import { Button, Chip, IconButton, Tooltip } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Delete, Edit, Add, Block } from "@material-ui/icons";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import PropTypes from "prop-types";
import React from "react";
import Switch from '@mui/material/Switch';
import { editReviewApi } from "API/review.api";
import { getSingleReviewApi } from "API/review.api";

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const useStyles = makeStyles(styles);

export default function ReviewsTable(props) {
  const classes = useStyles();
  const {
    tableHead,
    tableHeaderColor,
    tableData,
    setSelectedReview,
    handleOpenUpdateModal,
    deleteReview,
    setReviewList,
    setFilteredReviewList
  } = props;

  const handleEdit = async (review) => {
    if(review?.id){
      await getSingleReviewApi({ id: review?.id }).then((res) => {
        handleOpenUpdateModal(true);
        setSelectedReview(res);
      });
    }
    // handleOpenUpdateModal(true);
    // setSelectedReview(review);
  };
  const handleDelete = (review) => {
    deleteReview(review);
  };

  const handleIsLiveChange = async (event) => {
    let isLiveinput = event.isLive;

    let isLiveChange = !isLiveinput;

    const body = {
      id: event?.id,
      isLive: isLiveChange,
    };

    const newProduct = await editReviewApi(body);
    const newReviewList = tableData.map((cust) => {
      if (cust.id !== event.id) {
        return cust;
      } else {
        return { ...newProduct, isLiveChange };
      }
    });
    setReviewList([...newReviewList]);
    setFilteredReviewList([...newReviewList]);
  };

  const dataRows = () => {
    return tableData.map((row, index) => (
      <TableRow key={index} className={classes.tableBodyRow}>
        <TableCell className={classes.tableCell}>{row?.name}</TableCell>
        <TableCell className={classes.tableCell}>{row?.comment}</TableCell>
        <TableCell className={classes.tableCell}>
            <Switch {...label}
            checked={row?.isLive}
            onClick={() => handleIsLiveChange(row)}
            color="secondary" />
    </TableCell>
        <TableCell className={classes.tableCell}>
          {/* {row.isAvailable ? (
            <Tooltip title="Block">
              <IconButton onClick={() => handleCancel(row)}>
                <Block fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Un-Block">
              <IconButton onClick={() => handleUnCancel(row)}>
                <Add fontSize="small" />
              </IconButton>
            </Tooltip>
          )} */}
          <Tooltip title="Update Review">
            <IconButton onClick={() => handleEdit(row)}>
              <Edit fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Review">
            <IconButton onClick={() => handleDelete(row)}>
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </TableCell>
        {/* <TableCell className={classes.tableCell}>
          {row.isAvailable ? "Available" : "Not Available"}
        </TableCell> */}
      </TableRow>
    ));
  };
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>{dataRows()}</TableBody>
      </Table>
    </div>
  );
}

ReviewsTable.defaultProps = {
  tableHeaderColor: "gray",
};

ReviewsTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object),

  menus: PropTypes.array,
  setSelectedReview: PropTypes.func,
  handleOpenUpdateModal: PropTypes.func,
  deleteReview: PropTypes.func,
  cancelOffer: PropTypes.func,
  unCancelOffer: PropTypes.func,
};
