import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CreateReviewModal from "./CreateReviewModal";
import UpdateReviewModal from "./UpdateReviewModal";
import ReviewsTable from "components/Table/ReviewsTable";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Scrollbars from "react-custom-scrollbars";
import { getAllCustomersApi } from "API/customer.api";
import { getAllProductsApi } from "API/product.api";
import { getAllReviewsApi } from "API/review.api";
import { deleteReviewApi } from "API/review.api";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  createMenu: {
    marginLeft: "2%",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  ...styles,
  headerRoot: {
    display: "flex",
  },
  headerTitle: {
    flexGrow: "2",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    flexGrow: "1",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: (theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: (theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Review() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [reviewList, setReviewList] = useState([]);
  const [filteredReviewList, setFilteredReviewList] = useState([]);
  const [searched, setSearched] = useState("");
  const [selectedOffer, setSelectedOffer] = useState({});
  const [customerDropDown, setCustomerDropDown] = useState([]);
  const [productsDropDown, setProductsDropDown] = useState([]);
  const [selectedImagesToUpload, setSelectedImagesToUpload] = useState([]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImagesToUpload([]);
  };
  const handleOpenUpdateModal = () => {
    setOpenUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setOpenUpdateModal(false);
    setSelectedImagesToUpload([]);
  };

  const requestSearch = (searchValue) => {
    const filteredRows = reviewList.filter((row) => {
      return row.name.toLowerCase().includes(searchValue.toLowerCase());
    });
    setFilteredReviewList(filteredRows);
  };
  const onChangeSearch = (searchedValue) => {
    setSearched(searchedValue);
  };
  const deleteReview = async (review) => {
    await deleteReviewApi(review).then((res) => {
      const newReviewList = reviewList.filter(
        (singleReview) => singleReview?.id !== review?.id
      );
      setReviewList([...newReviewList]);
      setFilteredReviewList([...newReviewList]);
    });

  };

  useEffect(async () => {
    try {
      const customers = await getAllCustomersApi();
      setCustomerDropDown(customers);
    } catch (error) {
      setCustomerDropDown([]);
    }
  }, []);

  useEffect(async () => {
    try {
      const products = await getAllProductsApi();
      setProductsDropDown(products);
    } catch (error) {
      setProductsDropDown([]);
    }
  }, []);

  useEffect(async () => {
    try {
      const reviews = await getAllReviewsApi();
      setFilteredReviewList(reviews);
      setReviewList(reviews);
    } catch (error) {
      setFilteredReviewList([]);
      setReviewList([]);
    }
  }, []);

  return (
    <GridContainer>
      <CreateReviewModal
        open={open}
        onClose={handleClose}
        reviewList={reviewList}
        setReviewList={setReviewList}
        setFilteredReviewList={setFilteredReviewList}
        customerDropDown={customerDropDown}
        productsDropDown={productsDropDown}
        selectedImagesToUpload={selectedImagesToUpload}
        setSelectedImagesToUpload={setSelectedImagesToUpload}
      />
      <UpdateReviewModal
        open={openUpdateModal}
        onClose={handleCloseUpdateModal}
        reviewList={reviewList}
        setReviewList={setReviewList}
        setFilteredReviewList={setFilteredReviewList}
        review={selectedOffer}
        customerDropDown={customerDropDown}
        productsDropDown={productsDropDown}
        selectedImagesToUpload={selectedImagesToUpload}
        setSelectedImagesToUpload={setSelectedImagesToUpload}
      />
      <Button
        className={classes.createMenu}
        onClick={handleOpen}
        color="primary"
      >
        Create Review
      </Button>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.headerRoot}>
              <div className={classes.headerTitle}>
                <h4 className={classes.cardTitleWhite}>Reviews Table</h4>
                <p className={classes.cardCategoryWhite}>List of all reviews</p>
              </div>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Review Name..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={searched}
                  onChange={(e) => {
                    e.preventDefault();
                    onChangeSearch(e.target.value);
                    requestSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </CardHeader>
          <Scrollbars style={{ height: 500 }}>
            <CardBody>
              <ReviewsTable
                tableHeaderColor="primary"
                tableHead={[
                  "Name",
                  "Comment",
                  "isLive",
                  "Action"
                ]}
                tableData={filteredReviewList}
                handleOpenUpdateModal={handleOpenUpdateModal}
                deleteReview={deleteReview}
                setSelectedReview={setSelectedOffer}
                selectedOffer={selectedOffer}
                setReviewList={setReviewList}
                setFilteredReviewList={setFilteredReviewList}
              />
            </CardBody>
          </Scrollbars>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
