import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PropTypes from "prop-types";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@mui/material/FormControl";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Scrollbars from "react-custom-scrollbars";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { addNewProductApi } from "API/product.api";
import { addNewPromoCodeApi } from "API/promocode.api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content",
    margin: "10%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  image: {
    width: 256,
    height: 150,
  },
  formControl: {
    marginTop: "10%",
    minWidth: 60,
  },
}));

export default function CreatePromoCodesModal({
  open,
  onClose,
  promoCodesList,
  setPromoCodesList,
  setFilteredPromoCodesList,
}) {
  const classes = useStyles();

  const [progressing, setProgressing] = useState(false);
  const [code, setCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [errors, setErrors] = useState({});
  const [isUsed, setIsUsed] = useState(false);

  const validate = () => {
    let valid = true;
    let errorsTemp = {};

    if (!code) {
      valid = false;
      errorsTemp["code"] = "Promo Code is required";
    }

    if (
      promoCodesList.some((el) => el?.code?.toLowerCase() === code?.toLowerCase())
    ) {
      valid = false;
      errorsTemp["code"] = "Promo Code already exists.";
    }

    let priceError = validatePrice(discount);
    if (priceError !== "") {
      valid = false;
      errorsTemp["discount"] = priceError;
    }

    setErrors(errorsTemp);
    return valid;
  };

  const addNewPromoCode = async (e) => {
    setProgressing(true);
    e.preventDefault();
    if (validate()) {
      const body = {
        code,
        discount,
        isUsed,
      };
      try {
        const newPromoCode = await addNewPromoCodeApi(body);

        setPromoCodesList([...promoCodesList, newPromoCode]);
        setFilteredPromoCodesList([...promoCodesList, newPromoCode]);

        setCode("");
        setDiscount("");

        onClose();
        setProgressing(false);
      } catch (error) {
        onClose();
        setProgressing(false);
        return;
      }
    } else {
      setProgressing(false);
    }
  };

  const codeOnChange = (event) => {
    setCode(event.target.value);
    if (errors["code"]) {
      setErrors({ ...errors, code: null });
    }
  };

  const priceOnChange = (event) => {
    setDiscount(event.target.value);
    if (errors["discount"]) {
      setErrors({
        ...errors,
        discount: validatePrice(parseInt(event.target.value)),
      });
    }
  };


  const handleIsUsedChange = (event) => {
    setIsUsed(event.target.value === "true" ? true : false);
  };

  const validatePrice = (number) => {
    let result = "";
    if (!number || number === "") {
      result = "Discount Price is required";
    } else {
      var patt = new RegExp("[^0-9]");
      if (patt.test(number)) {
        // contains special chars
        result = "Discount Price should be a number";
      } else {
        if (number < 0) {
          // contains special chars
          result = "Discount Price should greater than 0";
        }
      }
    }
    return result;
  };

  if (progressing) {
    return (
      <div style={{ margin: "0 auto" }}>
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    );
  } else {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Scrollbars style={{ height: 500 }}>
          <Fade in={open}>
            <GridContainer>
              <GridItem xs={24} sm={24} md={18} style={{ width: "100%" }}>
                <Card>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Create Promo Code</h4>
                    <p className={classes.cardCategoryWhite}>
                      Enter Promo Code Details
                    </p>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Promo Code"
                          id="code"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={code}
                          onChange={codeOnChange}
                          error={errors["code"] ? true : false}
                          errorHelperText={errors["code"]}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                          labelText="Discount Price"
                          id="Discount"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={discount}
                          onChange={priceOnChange}
                          error={errors["discount"] ? true : false}
                          errorHelperText={errors["discount"]}
                        />
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={4}
                        style={{ display: "flex", margin: "auto" }}
                      >
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            Is Used
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="true"
                            name="radio-buttons-group"
                            value={isUsed}
                            onChange={handleIsUsedChange}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="True"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="False"
                            />
                          </RadioGroup>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" onClick={addNewPromoCode}>
                      Create Promo Code
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </Fade>
        </Scrollbars>
      </Modal>
    );
  }
}

CreatePromoCodesModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  promoCodesList: PropTypes.array,
  setPromoCodesList: PropTypes.func,
  setFilteredPromoCodesList: PropTypes.func,
};
