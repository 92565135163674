import axios from "axios";
import { verifyJwtToken } from "util/JwtExpireHelper.js";
import { getToast } from "util/ToastHelper.js";

import { getLocalStorageProperty } from "../util/LocalStorageHelper.js";

const getToken = () => getLocalStorageProperty("carryon-admin-token");
const rootUriOwner = process.env.REACT_APP_API_URL_OWNER;

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = "bearer " + getToken();
  return config;
});

export const addNewBulkImagesApi = async (imagesInput) => {
  try {
    const { data } = await axios.post(
      `${rootUriOwner}/images/bulk`,
      imagesInput
    );
    if (data) {
      // getToast(true, `Images added successfully`);
      let imagesIds = [];
      data.forEach((element) => {
        imagesIds.push({
          id: element?.id,
        });
      });
      return Promise.resolve(imagesIds);
    }
    getToast(false, data);
  } catch (error) {
    // getToast(false, error.response.data.message.message);
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, "Error");
    return Promise.reject();
  }
};

export const deleteImageApi = async (images) => {

  let deleteResult = [];

  try {
    for (let i = 0; i < images?.length; i++) {
      const body = { id: images[i].id };
      try {
        const { data } = await axios.delete(
          `${rootUriOwner}/images/${images[i].id}`,
          body
        );
        if (data) {
          // getToast(true, "Site Setting Deleted Successfully");
          deleteResult.push(data);
          // return Promise.resolve(data);
        } else {
          getToast(false, data);
          return Promise.reject({});
        }
      } catch (error) {
        if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
          await verifyJwtToken();
        }
        getToast(false, error);
        return Promise.reject({});
      }
    }
    return Promise.resolve(deleteResult);
  } catch (err) {
    if(JSON.stringify(err?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    return Promise.reject({});
  }
};
