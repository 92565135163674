import axios from "axios";
import { verifyJwtToken } from "util/JwtExpireHelper.js";
import { getToast } from "util/ToastHelper.js";

import {
  getLocalStorageProperty,
  setLocalStorageProperty,
} from "../util/LocalStorageHelper.js";

const getToken = () => getLocalStorageProperty("carryon-admin-token");
const rootUriOwner = process.env.REACT_APP_API_URL_OWNER;

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = "bearer " + getToken();
  return config;
});

export const alertNewOrdersCountApi = async () => {
  try {
    let ordersCountLocalStorage = getLocalStorageProperty(
      `flavors-orders-count`
    );
    ordersCountLocalStorage =
      ordersCountLocalStorage !== "undefined" &&
      JSON.parse(ordersCountLocalStorage);
    let lastApiCall = getLocalStorageProperty(
      `flavors-orders-count-api-called-time`
    );
    lastApiCall = lastApiCall && Date.parse(lastApiCall);
    const currentTime = new Date();
    if (lastApiCall) {
      const timeDiff = (currentTime - lastApiCall) / (1000 * 60);
      if (timeDiff <= 1) {
        return Promise.resolve(false);
      }
    }

    const { data } = await axios.get(`${rootUriOwner}/orders-count`);

    if (data.success) {
      const countData = data.data.data;
      //set orders count
      setLocalStorageProperty(
        `flavors-orders-count`,
        JSON.stringify(countData)
      );
      setLocalStorageProperty(
        `flavors-orders-count-api-called-time`,
        currentTime.toString()
      );
      //check if new orders available
      if (ordersCountLocalStorage !== "undefined") {
        let oldCount = ordersCountLocalStorage.count;
        if (oldCount < countData.count) {
          return Promise.resolve(true);
        } else {
          return Promise.resolve(false);
        }
      } else {
        return Promise.resolve(true);
      }
    } else {
      return Promise.reject(0);
    }
  } catch (error) {
    return Promise.reject(0);
  }
};

export const getAllOrdersApi = async () => {
  try {
    const { data } = await axios.get(`${rootUriOwner}/orders`);

    if (data) {
      // getToast(true, data.data.message);
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject([]);
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject([]);
  }
};

export const getOrderDetailsApi = async (body) => {
  try {
    const { data } = await axios.get(
      `${rootUriOwner}/orders/${body?.id}`,
      body
    );
    if (data?.id) {
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    return Promise.reject({});
  }
};

export const editOrderApi = async (body) => {
  try {
    const { data } = await axios.patch(
      `${rootUriOwner}/orders/${body?.id}`,
      body
    );
    if (data) {
      getToast(true, "Status Updated Sucessfully");
      return Promise.resolve(data);
    } else {
      getToast(false, data);
      return Promise.reject({});
    }
  } catch (error) {
    if(JSON.stringify(error?.response?.data?.message) === `"Unauthorized"`){
      await verifyJwtToken();
    }
    getToast(false, error);
    return Promise.reject({});
  }
};
