import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PropTypes from "prop-types";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { addNewReviewApi } from "API/review.api";
import Scrollbars from "react-custom-scrollbars";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import MultipleImageUploader from "components/MultipleImageUploader/MultipleImageUploader";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Autocomplete, TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content",
    margin: "10%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  image: {
    width: 256,
    height: 150,
  },
  formControl: {
    marginTop: "10%",
    minWidth: 60,
  },
}));

export default function CreateReviewModal({
  open,
  onClose,
  reviewList,
  setReviewList,
  setFilteredReviewList,
  customerDropDown,
  productsDropDown,
  selectedImagesToUpload,
  setSelectedImagesToUpload
}) {
  const classes = useStyles();

  const [progressing, setProgressing] = useState(false);
  const [name, setName] = useState("");
  const [customer, setCustomer] = useState(null);
  const [product, setProduct] = useState(null);
  const [isLive, setIsLive] = useState(true);
  const [comment, setComment] = useState("");
  const [errors, setErrors] = useState({});
  // const [selectedImagesToUpload, setSelectedImagesToUpload] = useState([]);
  const [imagesList, setImagesList] = useState([]);

  const validate = () => {
    let valid = true;
    let errorsTemp = {};

    if (selectedImagesToUpload?.length === 0) {
      valid = false;
      errorsTemp["imageUrl"] = "Image is required";
    }

    if (!name) {
      valid = false;
      errorsTemp["name"] = "Name is required";
    }

    if (!customer) {
      valid = false;
      errorsTemp["customer"] = "Customer is required";
    }
    if (!product) {
      valid = false;
      errorsTemp["product"] = "Product is required";
    }
    if (!comment) {
      valid = false;
      errorsTemp["comment"] = "Comment is required";
    }

    setErrors(errorsTemp);
    return valid;
  };

  const addNewReview = async (e) => {
    setProgressing(true);
    e.preventDefault();
    if (validate()) {
      const reveiwBody = {
        name,
        customer,
        product,
        comment,
        isLive,
        selectedImagesToUpload
      };
      try {
        const newReview = await addNewReviewApi({
          name,
          customer,
          product,
          comment,
          isLive,
          selectedImagesToUpload
        });

        setReviewList([...reviewList, newReview]);
        setFilteredReviewList([...reviewList, newReview]);

        setName("");
        setCustomer(null);
        setProduct(null);
        setIsLive(true);
        setComment("");
        setSelectedImagesToUpload([]);

        onClose();
        setProgressing(false);
      } catch (error) {
        onClose();
        setProgressing(false);
        return;
      }
    } else {
      setProgressing(false);
    }
  };

  const nameOnChange = (event) => {
    setName(event.target.value);
    if (errors["name"]) {
      setErrors({ ...errors, name: null });
    }
  };

  const isLiveOnChange = (event) => {
    setIsLive(event.target.value === "true" ? true : false);
    if (errors["isLive"]) {
      setErrors({ ...errors, isLive: null });
    }
  };

  const commentOnChange = (event) => {
    setComment(event.target.value);
    if (errors["comment"]) {
      setErrors({ ...errors, comment: null });
    }
  };

  if (progressing) {
    return (
      <div style={{ margin: "0 auto" }}>
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    );
  } else {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Scrollbars style={{ height: 500 }}>
          <Fade in={open}>
            <GridContainer>
              <GridItem xs={24} sm={24} md={18} style={{ width: "100%" }}>
                <Card>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Create Review</h4>
                    <p className={classes.cardCategoryWhite}>
                      Enter Review Details
                    </p>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Name"
                          id="Name"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={name}
                          onChange={nameOnChange}
                          error={errors["name"] ? true : false}
                          errorHelperText={errors["name"]}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <Autocomplete
                          value={customer}
                          onChange={(event, newValue) => {
                            setCustomer(newValue);
                            if (errors["customer"]) {
                              setErrors({ ...errors, customer: null });
                            }
                          }}
                          disablePortal
                          id="combo-box-demo"
                          options={customerDropDown}
                          getOptionLabel={(option) => option?.phoneNumber}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Customer"
                              variant="outlined"
                              error={errors["customer"] ? true : false}
                              helperText={errors["customer"]}
                            />
                          )}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Autocomplete
                          value={product}
                          onChange={(event, newValue) => {
                            setProduct(newValue);
                            if (errors["product"]) {
                              setErrors({ ...errors, product: null });
                            }
                          }}
                          disablePortal
                          id="combo-box-demo"
                          options={productsDropDown}
                          getOptionLabel={(option) => option?.name}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Product"
                              error={errors["product"] ? true : false}
                              helperText={errors["product"]}
                            />
                          )}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={8}>
                        {/* <TextareaAutosize
                          aria-label="empty textarea"
                          id="comment"
                          placeholder="Type the review here..."
                          style={{ width: "80%", height: 100 }}
                          value={comment}
                          onChange={commentOnChange}
                          error={errors["comment"] ? true : false}
                          helperText={errors["comment"]}
                        /> */}
                        <TextField
                          id="comment"
                          placeholder="Type the review here..."
                          style={{ width: "80%", height: 100 }}
                          multiline
                          InputProps={{
                            inputComponent: TextareaAutosize,
                            inputProps: {
                              style: {
                                resize: "auto"
                              }
                            }
                          }}
                          value={comment}
                          onChange={commentOnChange}
                          error={errors["comment"] ? true : false}
                          helperText={errors["comment"]}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            Is Live
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="true"
                            name="radio-buttons-group"
                            value={isLive}
                            onChange={isLiveOnChange}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="Live"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="Not Live"
                            />
                          </RadioGroup>
                        </FormControl>
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <MultipleImageUploader
                          error={errors["imageUrl"] ? true : false}
                          errorHelperText={errors["imageUrl"]}
                          setSelectedImagesToUpload={setSelectedImagesToUpload}
                          setImagesList={setImagesList}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" onClick={addNewReview}>
                      Create Review
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </Fade>
        </Scrollbars>
      </Modal>
    );
  }
}

CreateReviewModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  reviewList: PropTypes.array,
  setReviewList: PropTypes.func,
  setFilteredReviewList: PropTypes.func,
};
